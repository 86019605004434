import { BaseRepository } from 'common/redux/sitka/basemap'
import { FetchStatus } from 'enums'

export interface OctainPredictionFiles {
  'log.txt': string
  'sk_log.txt': string
  'prediction.csv': string
}

export interface OctainPredictionFileData {
  log: string
  logStatus: FetchStatus
}

export interface OctainPredictionResults extends OctainPrediction {
  readonly fileData: OctainPredictionFileData
}

export interface OctainPrediction {
  readonly id: string
  readonly state: string
  readonly error: string
  readonly name: string
  readonly idVariable: string
  readonly responseVariable: string
  readonly method: string
  readonly predictionVariables: string
  readonly preview: Array<Array<string>>
  readonly createdAt: string
  readonly updatedAt: string
  readonly datasetId: string
  readonly datasetName: string
  readonly modelId: string
  readonly modelName: string
  readonly archived: boolean
  readonly files: OctainPredictionFiles
}

export interface OctainPredictions extends BaseRepository<OctainPrediction> {}

export interface OctainPredictionState {
  readonly predictions: OctainPredictions
  readonly status: FetchStatus
  readonly predictionResults: OctainPredictionResults
  readonly predictionResultsStatus: FetchStatus
}

export const defaultOctainPredictionFiles = {
  'log.txt': '',
  'sk_log.txt': '',
  'prediction.csv': ''
}

export const defaultOctainPredictionFileData = (
  state: FetchStatus
): OctainPredictionFileData => ({
  log: '',
  logStatus: state
})

export const defaultOctainPredictionResults: OctainPredictionResults = {
  id: '',
  state: '',
  error: '',
  name: '',
  idVariable: '',
  responseVariable: '',
  method: '',
  predictionVariables: '',
  preview: [[]],
  createdAt: '',
  updatedAt: '',
  datasetId: '',
  datasetName: '',
  modelId: '',
  modelName: '',
  archived: false,
  files: defaultOctainPredictionFiles,
  fileData: defaultOctainPredictionFileData(FetchStatus.none)
}
