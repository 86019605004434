import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { DatasetModule } from 'common/redux/sitka/octain_dataset/module'
import { ModelModule } from 'common/redux/sitka/octain_model/module'
import { PredictionModule } from 'common/redux/sitka/octain_prediction/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import modelStyles from 'ui/components/models/model_styles'
import {
  H1,
  H2,
  Container,
  PageHeadingContainer,
  SLink,
  RefreshButton
} from 'ui/styledComponents/constants'
import { ButtonPrimary, SmallToggleButton } from 'ui/styledComponents/buttons'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import { ModelList } from 'ui/components/models/model_list'
import { useHistory } from 'react-router'
import { PageButtons } from 'ui/components/page-buttons/page-buttons'
import { FetchStatus } from 'enums'

interface ModelsProps {
  readonly currentSession: CurrentSessionModule
  readonly datasetModule: DatasetModule
  readonly modelModule: ModelModule
  readonly predictionModule: PredictionModule
  readonly sitkaState: AppState
}

const Component: React.FC<ModelsProps> = ({
  datasetModule,
  modelModule,
  currentSession,
  sitkaState: {
    models: { models, status }
  }
}) => {
  const history = useHistory()
  const [archived, setArchived] = useState<boolean>(false)
  useEffect(() => {
    modelModule.handleGetModels({})
  }, [])

  const openNewModel = () => {
    datasetModule.handleGetDatasets({})
    history.push('/models/new/dataset')
  }

  const {
    models: {
      modelHeading,
      archiveModelHeading,
      newModelButton,
      noModels,
      createFirst
    },
    common: { networkError }
  } = strings
  const {
    EmptyModelList,
    LoadingSpinner,
    ModelHeadingIcon,
    PageButtonContainer,
    ModelArchiveIcon
  } = modelStyles

  const loading = (
    <>
      <EmptyModelList>
        <LoadingSpinner />
      </EmptyModelList>
      <PageButtonContainer>
        <PageButtons />
      </PageButtonContainer>
    </>
  )
  const none = loading

  const error = (
    <>
      <EmptyModelList>
        <H1>{networkError}</H1>
      </EmptyModelList>
      <PageButtonContainer>
        <PageButtons />
      </PageButtonContainer>
    </>
  )

  const done =
    status === FetchStatus.done && models.sort.length === 0 ? (
      <>
        <EmptyModelList>
          {archived ? (
            <h2>{'No Models in archive'}</h2>
          ) : (
            <h2>{'No Models'}</h2>
          )}
        </EmptyModelList>
        <PageButtonContainer>
          <PageButtons />
        </PageButtonContainer>
      </>
    ) : (
      <ModelList models={models} />
    )

  const toggleArchive = () => {
    modelModule.handleGetModels({ archived: !archived })
    setArchived(!archived)
  }

  const getModels = () => {
    modelModule.handleGetModels({ archived: archived })
  }

  return (
    <Container>
      <PageHeadingContainer>
        <H2>
          <ModelHeadingIcon />
          {archived ? archiveModelHeading : modelHeading}
        </H2>
        <PageButtonContainer>
          <ButtonPrimary onClick={openNewModel}>{newModelButton}</ButtonPrimary>
          <SmallToggleButton
            onClick={getModels}
            active={status === FetchStatus.loading}
          >
            <RefreshButton
              processing={status === FetchStatus.loading ? 1 : 0}
            />
          </SmallToggleButton>
          <SmallToggleButton onClick={toggleArchive} active={archived}>
            <ModelArchiveIcon />
          </SmallToggleButton>
        </PageButtonContainer>
      </PageHeadingContainer>
      <LoadingWrapper {...{ done, loading, error, none, status }} />
    </Container>
  )
}

export const Models = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    datasetModule: modules.datasets,
    modelModule: modules.models,
    predictionModule: modules.predictions,
    sitkaState: state
  }
})(Component)
