import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { PredictionList } from 'ui/components/predictions/prediction_list'
import { ModelModule } from 'common/redux/sitka/octain_model/module'
import { PredictionModule } from 'common/redux/sitka/octain_prediction/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { ButtonPrimary, SmallToggleButton } from 'ui/styledComponents/buttons'
import {
  SLink,
  Container,
  H2,
  PageHeadingContainer,
  H1,
  RefreshButton
} from 'ui/styledComponents/constants'
import predictions_styles from 'ui/components/predictions/predictions_styles'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import { PageButtons } from 'ui/components/page-buttons/page-buttons'
import { FetchStatus } from 'enums'

interface PredictionsProps {
  readonly modelModule: ModelModule
  readonly predictionModule: PredictionModule
  readonly sitkaState: AppState
}

const Component: React.FC<PredictionsProps> = ({
  predictionModule,
  sitkaState: {
    predictions: { predictions, status }
  }
}) => {
  const [archived, setArchived] = useState<boolean>(false)
  useEffect(() => {
    predictionModule.handleGetPredictions({ archived: false })
  }, [])

  const {
    EmptyPredictionsList,
    PredictionsHeadingIcon,
    PageButtonContainer,
    LoadingSpinner,
    PredictionArchiveIcon
  } = predictions_styles

  const loading = (
    <>
      <EmptyPredictionsList>
        <LoadingSpinner />
      </EmptyPredictionsList>
      <PageButtonContainer>
        <PageButtons />
      </PageButtonContainer>
    </>
  )

  const none = loading

  const error = (
    <>
      <EmptyPredictionsList>
        <H1>Network Error</H1>
      </EmptyPredictionsList>
      <PageButtonContainer>
        <PageButtons />
      </PageButtonContainer>
    </>
  )

  const done =
    status === FetchStatus.done && predictions.sort.length === 0 ? (
      <>
        <EmptyPredictionsList>
          {archived ? (
            <h2>{'No Predictions in archive'}</h2>
          ) : (
            <h2>{'No Predictions'}</h2>
          )}
        </EmptyPredictionsList>
        <PageButtonContainer>
          <PageButtons />
        </PageButtonContainer>
      </>
    ) : (
      <PredictionList predictions={predictions} />
    )

  const toggleArchive = () => {
    predictionModule.handleGetPredictions({ archived: !archived })
    setArchived(!archived)
  }

  const getPredictions = () => {
    predictionModule.handleGetPredictions({ archived: archived })
  }

  return (
    <Container>
      <PageHeadingContainer>
        <H2>
          <PredictionsHeadingIcon />
          {archived ? 'Predictions - Archive' : 'Predictions'}
        </H2>
        <PageButtonContainer>
          <SLink to={'/predictions/new'}>
            <ButtonPrimary>New Prediction</ButtonPrimary>
          </SLink>
          <SmallToggleButton
            onClick={getPredictions}
            active={status === FetchStatus.loading}
          >
            <RefreshButton
              processing={status === FetchStatus.loading ? 1 : 0}
            />
          </SmallToggleButton>
          <SmallToggleButton onClick={toggleArchive} active={archived}>
            <PredictionArchiveIcon />
          </SmallToggleButton>
        </PageButtonContainer>
      </PageHeadingContainer>
      <LoadingWrapper {...{ done, loading, error, none, status }} />
    </Container>
  )
}

export const Predictions = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    modelModule: modules.models,
    predictionModule: modules.predictions,
    sitkaState: state
  }
})(Component)
