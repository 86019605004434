import { SitkaModule } from 'olio-sitka'
import { put, select } from 'redux-saga/effects'
import { AppModules, AppState } from 'common/redux/sitka/sitka_octain'

import { getUsers, ResponseModel } from 'common/client'
import { AdminState } from 'common/redux/sitka/admin/core'
import { FetchStatus } from 'enums'
import {
  defaultRepository,
  parseArrayToBaseRepo
} from 'common/redux/sitka/basemap'
export class AdminModule extends SitkaModule<AdminState, AppModules> {
  public moduleName: string = 'admin'

  public defaultState: AdminState = {
    users: defaultRepository(),
    status: FetchStatus.none
  }

  public *handleGetUsers() {
    const adminState: AdminState = yield select(AdminModule.selectAdminState)

    yield put(
      this.setState({ ...this.defaultState, status: FetchStatus.loading })
    )

    const rawUsers: ResponseModel = yield this.modules.currentSession.authCheck(
      yield getUsers()
    )
    if (rawUsers.status !== 200) {
      yield put(
        this.setState({ ...this.defaultState, status: FetchStatus.error })
      )
      return
    }
    yield put(
      this.setState({
        ...adminState,
        users: parseArrayToBaseRepo(rawUsers.data),
        status: FetchStatus.done
      })
    )
  }

  public static selectAdminState(state: AppState): AdminState {
    return state.admin
  }
}
