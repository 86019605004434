import { ResponseModel } from 'common/client'
import { FetchStatus } from 'enums'
import { OctainModel } from 'common/redux/sitka/octain_model/core'
import { parseCSV } from 'common/redux/sitka/octain_dataset/util'

export const getResponseStatus = (response: ResponseModel): FetchStatus =>
  response.status === 200 ? FetchStatus.done : FetchStatus.error

export const getDataFromResponse = (response: ResponseModel): any =>
  response.status === 200 ? response.data : ','

export const parseCSVinModels = (octainModels: Array<any>): Array<any> => {
  return octainModels.map(model => parseCSVinModel(model))
}

export const parseCSVinModel = (octainModel: OctainModel): OctainModel => ({
  ...octainModel,
  tbl: octainModel.tbl ? parseCSV(octainModel.tbl) : octainModel.tbl,
  top20: octainModel.top20 ? parseCSV(octainModel.top20) : octainModel.top20
})
