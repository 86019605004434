import React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'common/redux/sitka/sitka_octain'
import { ModelResultsTable } from 'ui/components/models/model_results/model_results_table'
import { H2, SLink } from 'ui/styledComponents/constants'
import { FetchStatus } from 'enums'
import { Button } from 'ui/styledComponents/buttons'
import results_styles from 'ui/styledComponents/results_styles'
import { strings } from 'ui/components/strings'

interface Props {
  readonly sitkaState: AppState
}

const Component: React.FC<Props> = ({
  sitkaState: {
    models: {
      modelResults: {
        datasetId,
        accuracy,
        createdAt,
        error,
        files,
        fn,
        fp,
        id,
        idVariable,
        method,
        name,
        partialDependence,
        precision,
        predictionVariables,
        recall,
        responseVariable,
        state,
        tbl,
        tn,
        top20,
        tp,
        trainingTime,
        datasetName
      },
      modelResultsStatus
    }
  }
}) => {
  const { ErrorContainer } = results_styles

  if (modelResultsStatus !== FetchStatus.done) {
    return (
      <ErrorContainer>
        <H2>Error Loading Artifact</H2>
      </ErrorContainer>
    )
  }

  const parametersTable = [
    ['Parameter', 'Value'],
    ['Dataset', datasetName],
    ['Id Variable', idVariable],
    ['Response Variable', responseVariable],
    ['Method', strings.modelMethod(method)],
    ['Predictor Variables', predictionVariables],
    ['Training Time', trainingTime],
    ['Created On', createdAt]
  ]

  return (
    <ModelResultsTable table={parametersTable} status={modelResultsStatus} />
  )
}

export const ModelResultsParameters = connect((state: AppState) => {
  return {
    sitkaState: state
  }
})(Component)
