import styled from 'styled-components'
import { Card, ICardProps, Icon, IIconProps } from '@blueprintjs/core'
import { flex, padding, margin, color } from 'ui/styledComponents/constants'
import { Link } from 'react-router-dom'

export default {
  Container: styled.div`
    height: calc(100vh - 80px);
    ${flex.flexCenteredRow}
    ${padding.p2}
    justify-content: center;
    ${color.FGPrimaryA}
    @media (max-width: 720px) {
      flex-direction: column;
    }
  `,
  Card: styled(Card).attrs(
    (): ICardProps => ({ interactive: true, elevation: 2 })
  )`
    && {
      width: 350px;
      height: 250px;
      min-width: 300px;
    }
  `,
  CardLink: styled(Link)`
    color: black;
    ${margin.m3}
    &:hover {
      text-decoration: none;
    }
  `,
  ModelIcon: styled(Icon).attrs(
    (): IIconProps => ({
      icon: 'search-around'
    })
  )``,
  PredictIcon: styled(Icon).attrs(
    (): IIconProps => ({
      icon: 'chart'
    })
  )``
}
