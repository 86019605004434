export interface ModelWizard {
  readonly stage: modelWizardStage
  readonly datasetId: string
  readonly modelName: string
  readonly predictors: ReadonlyArray<string>
  readonly responseVariable: string
  readonly trainingTime: string
  readonly idVariable: string
  readonly method: string
}

export interface CurrentSession {
  readonly name: string
  readonly picture: string
  readonly email: string
  readonly permissions?: string
}

export interface CurrentSessionState {
  readonly redirect: string
  readonly session: CurrentSession
  readonly auth: authState
  readonly errorCode: string
}

export enum authState {
  'CHECKTOKEN',
  'UNAUTHENTICATED',
  'PROCESSING',
  'AUTHENTICATED'
}

export enum modelWizardStage {
  'CHOOSE_DATASET',
  'CONFIGURE_MODEL',
  'PROCESSING'
}

export enum predictionWizardStage {
  'CHOOSE_MODEL',
  'CHOOSE_DATASET',
  'PREVIEW',
  'PROCESSING'
}

export const modelWizardDefault: ModelWizard = {
  stage: modelWizardStage.CHOOSE_DATASET,
  idVariable: '',
  trainingTime: '30',
  responseVariable: '',
  datasetId: '',
  modelName: '',
  method: 'cls',
  predictors: []
}

export interface PredictionWizard {
  readonly stage: predictionWizardStage
  readonly predictionName: string
  readonly datasetId: string
  readonly modelId: string
}

export const predictionWizardDefault: PredictionWizard = {
  stage: predictionWizardStage.CHOOSE_MODEL,
  predictionName: '',
  datasetId: '',
  modelId: ''
}
