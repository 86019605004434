import styled from 'styled-components'
import { margin, color } from 'ui/styledComponents/constants'

export const Button = styled.button`
  min-width: 140px;
  min-height: 46px;
  padding: 5px 15px;
  font-size: 16px;
  border: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: middle;
  text-align: left;
  ${margin.m1}
`

export const ButtonPrimary = styled(Button)`
  ${color.PrimaryA} :hover {
    ${color.PrimaryADark}
  }
`

export const ButtonSecondary = styled(Button)`
  ${color.PrimaryB} :hover {
    ${color.PrimaryBDark}
  }
`
export const ButtonHollow = styled(Button)`
  border: 1px solid #6867f2;
  background-color: white;
  color: black;
  ${color.PrimaryA} :hover {
    ${color.PrimaryA}
    color: white;
  }
`

interface ToggleProps {
  readonly active: boolean
}

const colorPrimaryInactive = `
background-color: #c6cbd1;
    :hover {
      background-color: #aab2bb;
    }
`
const colorPrimaryActive = `
background-color: #26de81;
  :hover {
    background-color: #1bb669;
  }
`

export const SmallToggleButton = styled(Button)<ToggleProps>`
  color: white;
  && {
    min-width: auto;
  }
  ${props => (props.active ? colorPrimaryActive : colorPrimaryInactive)}
`
export const SmallButton = styled(Button)`
  color: white;
  && {
    min-width: auto;
  }
  background-color: #c6cbd1;
  :hover {
    background-color: #aab2bb;
  }
`
