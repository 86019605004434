import styled from 'styled-components'
import {
  flex,
  screenHeight,
  margin,
  color
} from 'ui/styledComponents/constants'
import { Spinner } from '@blueprintjs/core'
import { Button } from 'ui/styledComponents/buttons'

export const loginStyles = {
  Container: styled.div`
    height: 100vh;
    ${flex.flexCenteredColumn}
    background: #1680ff;
    justify-content: center;
    button {
      margin: 10px;
    }
  `,
  LogoContainer: styled.div`
    width: 100vmin;
    #height: calc(100vmin * 0.457);
    height: calc(100vmin * 0.6);
  `,
  Logo: styled.img`
    width: 100%;
  `,
  LoadingSpinner: styled(Spinner).attrs(() => ({
    className: 'LoadingSpinner',
    size: 80
  }))`
    && {
      margin-bottom: calc(10vh - 24px);
    }
  `,
  LoginButton: styled(Button)`
    && {
      margin-bottom: 10vh;
    }
    ${color.PrimaryB} :hover {
      ${color.PrimaryBDark}
    }
  `
}
