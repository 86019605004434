import {
  ButtonGroup,
  IButtonGroupProps,
  Button,
  IButtonProps
} from '@blueprintjs/core'
import styled from 'styled-components'

export default {
  ButtonGroup: styled(ButtonGroup).attrs(
    (): IButtonGroupProps => ({ minimal: true, alignText: 'center' })
  )`
    padding-top: 10px;
    padding-bottom: 10px;
  `,
  LeftButton: styled(Button).attrs(
    (): IButtonProps => ({ large: true, icon: 'chevron-left' })
  )`
    width: 60px;
  `,
  NumberButton: styled(Button).attrs((): IButtonProps => ({ large: true }))`
    width: 60px;
  `,
  RightButton: styled(Button).attrs(
    (): IButtonProps => ({ large: true, icon: 'chevron-right' })
  )`
    width: 60px;
  `
}
