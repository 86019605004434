import styled from 'styled-components'
import { flex, padding } from 'ui/styledComponents/constants'
import { Spinner, Icon } from '@blueprintjs/core'
import { Button } from 'ui/styledComponents/buttons'

export default {
  EmptyModelList: styled.div`
    width: 100%;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
      0 1px 1px rgba(16, 22, 26, 0.2);
    border-radius: 5px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 740px;
    overflow-y: hidden;
    overflow-x: auto;
  `,
  LoadingSpinner: styled(Spinner).attrs(() => ({
    className: 'LoadingSpinner',
    size: 100
  }))``,
  ModelHeadingIcon: styled(Icon).attrs(() => ({
    icon: 'search-around',
    iconSize: 20,
    className: 'ModelHeadingIcon'
  }))`
    margin-right: 8px;
  `,
  PageButtonContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
  ModelArchiveIcon: styled(Icon).attrs(() => ({
    icon: 'archive',
    iconSize: 20
  }))``
}
