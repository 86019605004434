import styled from 'styled-components'
import { Card, ICardProps, Icon, IIconProps, Button } from '@blueprintjs/core'
import { flex, padding, margin, cn, color } from 'ui/styledComponents/constants'

const MenuButton = styled(Button)`
  &&&& {
    color: white;
    &:hover {
      ${color.FGPrimaryB}
    }
  }
`

export default {
  UserBox: styled.div.attrs(cn('UserBox'))`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 10px;
    @media (max-width: 720px) {
      display: none;
    }
  `,
  Email: styled.div.attrs(cn('Email'))`
    ${color.FGPrimaryB}
    font-size: 14px;
  `,
  Navbar: styled.div.attrs(props => ({ className: 'bp3-dark NavBar' }))`
    ${color.BGSecondaryA}
    ${flex.flexCenteredRow}
    height: 80px;
    padding: 0 20px;
    position: fixed;
    top: 0px;
    width: 100%;
  `,
  Group: styled.div.attrs(cn('Group'))`
    ${flex.flexCenteredRow}
    height: 50px;
  `,
  UserGroup: styled.div.attrs(cn('UserGroup'))`
    ${flex.flexCenteredRow}
    height: 50px;
    margin-left: auto;
  `,

  UserCard: styled.div.attrs(() => ({
    className: 'bp3-navbar-heading UserCard'
  }))`
    ${flex.flexCenteredRow}
    justify-content: center;

    img {
      width: 40px;
      border-radius: 50%;
      margin-right: 5px;
    }
  `,
  MenuItems: {
    Models: styled(MenuButton).attrs(() => ({
      icon: 'search-around',
      className: 'bp3-minimal'
    }))``,
    Predictions: styled(MenuButton).attrs(() => ({
      icon: 'chart',
      className: 'bp3-minimal'
    }))``,
    Results: styled(MenuButton).attrs(() => ({
      icon: 'grouped-bar-chart',
      className: 'bp3-minimal'
    }))``,
    Admin: styled(MenuButton).attrs(() => ({
      icon: 'person',
      className: 'bp3-minimal'
    }))``,
    Logout: styled(MenuButton).attrs(cn('bp3-minimal Logout'))``
  },
  Heading: styled(MenuButton).attrs(() => ({
    className: 'bp3-navbar-heading bp3-minimal'
  }))``,
  HeadingIcon: styled.button`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    padding: 5px 10px;

    border: none;
    background: none;
    cursor: pointer;
    border-radius: 3px;
    &:hover {
      background: rgba(138, 155, 168, 0.15);
    }
    &:active {
      background: rgba(138, 155, 168, 0.3);
      color: #f5f8fa;
    }
    img {
      height: 47px;
    }
  `,
  Divider: styled.div.attrs(() => ({
    className: 'bp3-navbar-divider Divider'
  }))``
}
