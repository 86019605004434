import React, { useState } from 'react'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { DatasetModule } from 'common/redux/sitka/octain_dataset/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import { H4, PageHeadingContainer, H2 } from 'ui/styledComponents/constants'
import { ButtonPrimary } from 'ui/styledComponents/buttons'
import configure_model_styles from 'ui/components/models/model_create/model_configure_style'
import { PreviewList } from 'ui/components/models/model_create/preview_list'
import {
  InputGroup,
  MenuItem,
  Popover,
  RadioGroup,
  Radio
} from '@blueprintjs/core'
import { Select, ItemPredicate, ItemRenderer } from '@blueprintjs/select'
import { useHistory } from 'react-router'
import uuid from 'uuid'
import {
  ModelWizard,
  modelWizardStage
} from 'common/redux/sitka/current_session/core'
import model_styles from 'ui/components/models/model_styles'

interface ModelConfigCheck {
  readonly modelName: boolean
  readonly trainingTime: boolean
  readonly idVariable: boolean
  readonly responseVariable: boolean
  readonly predictionVariable: boolean
}

const ModelConfigCheckDefault = {
  modelName: true,
  trainingTime: true,
  idVariable: true,
  responseVariable: true,
  predictionVariable: true
}

interface ConfigureModelProps {
  readonly currentSession: CurrentSessionModule
  readonly datasetModule: DatasetModule
  readonly sitkaState: AppState
  readonly modelWizard: ModelWizard
  readonly setModelWizard: React.Dispatch<React.SetStateAction<ModelWizard>>
}

const Component: React.FC<ConfigureModelProps> = ({
  modelWizard,
  setModelWizard,
  currentSession,
  sitkaState: {
    datasets: { datasets }
  }
}) => {
  const history = useHistory()
  const {
    idVariable,
    predictors,
    datasetId,
    responseVariable,
    modelName,
    trainingTime,
    method
  } = modelWizard

  const columns: Array<string> = [...datasets.items[datasetId].columnNames]
  const [formAlert, setFormAlert] = useState<ModelConfigCheck>(
    ModelConfigCheckDefault
  )
  const [predictorQuery, setPredictorQuery] = useState('')
  const st = strings.modelConfigure
  const {
    PredictorContainer,
    SettingsContainer,
    PredictorInput,
    AlertBox,
    AlertText,
    ConfigureContainer
  } = configure_model_styles
  const { ModelHeadingIcon } = model_styles

  const togglePredictors = (predictor: string) => {
    let newPredictors = []

    if (modelWizard.predictors.includes(predictor)) {
      newPredictors = modelWizard.predictors.filter(
        (item: string) => item !== predictor
      )
    } else {
      newPredictors = [...modelWizard.predictors, predictor]
    }
    // Check for Overlap
    const newIdVariable =
      predictor === modelWizard.idVariable ? '' : modelWizard.idVariable
    const newResponseVariable =
      predictor === modelWizard.responseVariable
        ? ''
        : modelWizard.responseVariable

    setModelWizard({
      ...modelWizard,
      predictors: newPredictors,
      idVariable: newIdVariable,
      responseVariable: newResponseVariable
    })
  }

  const setPredictors = (predictors: ReadonlyArray<string>) => {
    setModelWizard({
      ...modelWizard,
      predictors: predictors
    })
  }

  const onSelectColumn = (id: string) => {
    if (id !== '0') {
      const predictor = columns[parseInt(id)]
      togglePredictors(predictor)
    }
  }

  const onSelectPredictor = (name: string) => {
    if (name !== '') {
      togglePredictors(name)
    }
  }

  const selectAll = () => {
    setPredictors(
      columns.filter(
        (item: string, i: number) =>
          item !== idVariable && item !== responseVariable && i !== 0
      )
    )
  }

  const selectNone = () => {
    setPredictors([])
  }

  const selectToggle = () => {
    setPredictors(
      columns.filter(
        item =>
          !predictors.includes(item) &&
          item !== idVariable &&
          item !== responseVariable
      )
    )
  }

  const predictorColumns = predictors.map(predictor => {
    for (let i = 1; i < columns.length; i++) {
      if (columns[i] === predictor) {
        return i
      }
    }
    return -1
  })

  const idColumnFunction = () => {
    for (let i = 1; i < columns.length; i++) {
      if (columns[i] === idVariable) {
        return i
      }
    }
    return -1
  }

  const responseColumnFunction = () => {
    for (let i = 1; i < columns.length; i++) {
      if (columns[i] === responseVariable) {
        return i
      }
    }
    return -1
  }

  const idColumn = idColumnFunction()
  const responseColumn = responseColumnFunction()

  const IdVarSelect = Select.ofType<string>()
  const ResponseVarSelect = Select.ofType<string>()

  const filterString: ItemPredicate<string> = (query, item, _index) => {
    const normalizedItem = item.toLowerCase()
    const normalizedQuery = query.toLowerCase()
    return normalizedItem.includes(normalizedQuery)
  }

  const selectIdVariable = (newIdVariable: string) => {
    const newPredictors = modelWizard.predictors.filter(
      item => item !== newIdVariable
    )
    const newResponseVariable =
      newIdVariable === modelWizard.responseVariable
        ? ''
        : modelWizard.responseVariable

    setModelWizard({
      ...modelWizard,
      idVariable: newIdVariable,
      predictors: newPredictors,
      responseVariable: newResponseVariable
    })
  }

  const selectResponseVariable = (newResponseVariable: string) => {
    const newPredictors = modelWizard.predictors.filter(
      item => item !== newResponseVariable
    )
    const newIdVariable =
      newResponseVariable === modelWizard.idVariable
        ? ''
        : modelWizard.idVariable
    setModelWizard({
      ...modelWizard,
      idVariable: newIdVariable,
      predictors: newPredictors,
      responseVariable: newResponseVariable
    })
  }

  const handleModelNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModelWizard({
      ...modelWizard,
      modelName: event.target.value
    })
  }

  const handleTrainingTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const number = event.target.value.replace(/\D/g, '')

    setModelWizard({
      ...modelWizard,
      trainingTime: number
    })
  }

  const handleChangeMethod = (event: any) => {
    setModelWizard({
      ...modelWizard,
      method: event.target.value
    })
  }

  const trainModel = () => {
    let configCheck: ModelConfigCheck = {
      idVariable: idVariable !== '',
      modelName: modelName.length !== 0,
      predictionVariable: predictors.length !== 0,
      responseVariable: responseVariable !== '',
      trainingTime: trainingTime !== '' && parseInt(trainingTime) >= 30
    }
    setFormAlert(configCheck)
    for (let check of Object.values(configCheck)) {
      if (check === false) {
        return
      }
    }
    currentSession.handleModelCreateTrainModel(modelWizard)
    setModelWizard({
      ...modelWizard,
      stage: modelWizardStage.PROCESSING
    })
    history.push('/models/new/processing')
  }

  const trimmedColumn = columns.slice(1)

  return (
    <>
      <PageHeadingContainer>
        <H2>
          <ModelHeadingIcon />
          {'Create Model - Configure'}
        </H2>
        <ButtonPrimary onClick={trainModel}>{'Fit Model'}</ButtonPrimary>
      </PageHeadingContainer>
      <H4>{st.previewHeading}</H4>

      <ConfigureContainer>
        <PreviewList
          idColumn={idColumn}
          responseColumn={responseColumn}
          predictorColumns={predictorColumns}
          onSelectItem={onSelectColumn}
          dataset={datasets.items[datasetId]}
        />
        <SettingsContainer>
          <PredictorContainer>
            <H4>{st.nameHeading}</H4>
            <Popover
              isOpen={!formAlert.modelName}
              onOpened={() =>
                setTimeout(() => setFormAlert(ModelConfigCheckDefault), 1200)
              }
            >
              <InputGroup
                large
                onChange={handleModelNameChange}
                placeholder={st.namePlaceholder}
              />
              <AlertBox>
                <AlertText>{st.nameAlertText}</AlertText>
              </AlertBox>
            </Popover>
            <H4>{st.trainingTimeHeading}</H4>
            <Popover
              isOpen={!formAlert.trainingTime}
              onOpened={() =>
                setTimeout(() => setFormAlert(ModelConfigCheckDefault), 1200)
              }
            >
              <InputGroup
                type={'number'}
                large
                onChange={handleTrainingTimeChange}
                placeholder={st.trainingTimePlaceholder}
                value={modelWizard.trainingTime}
              />
              <AlertBox>
                <AlertText>{st.trainingTimeAlert}</AlertText>
              </AlertBox>
            </Popover>
            <H4>{st.idVariable}</H4>
            <Popover
              isOpen={!formAlert.idVariable}
              onOpened={() =>
                setTimeout(() => setFormAlert(ModelConfigCheckDefault), 1200)
              }
            >
              <IdVarSelect
                popoverProps={{ minimal: false }}
                resetOnClose
                itemPredicate={filterString}
                items={trimmedColumn}
                onItemSelect={selectIdVariable}
                itemRenderer={renderMenuItem}
                noResults={
                  <MenuItem disabled={true} text={st.idVariableNoResults} />
                }
              >
                <ButtonPrimary>
                  {idVariable || st.idVariableButton}
                </ButtonPrimary>
              </IdVarSelect>
              <AlertBox>
                <AlertText>{st.idVariableAlert}</AlertText>
              </AlertBox>
            </Popover>
            <H4>{st.responseVariable}</H4>
            <Popover
              isOpen={!formAlert.responseVariable}
              onOpened={() =>
                setTimeout(() => setFormAlert(ModelConfigCheckDefault), 1200)
              }
            >
              <ResponseVarSelect
                popoverProps={{ boundary: 'window' }}
                resetOnClose
                itemPredicate={filterString}
                items={trimmedColumn}
                onItemSelect={selectResponseVariable}
                itemRenderer={renderMenuItem}
                noResults={
                  <MenuItem
                    disabled={true}
                    text={st.responseVariableNoResults}
                  />
                }
              >
                <ButtonPrimary>
                  {responseVariable || st.responseVariableButton}
                </ButtonPrimary>
              </ResponseVarSelect>
              <AlertBox>
                <AlertText>{st.responseVariableAlert}</AlertText>
              </AlertBox>
            </Popover>
            <H4>{st.trainingMethodHeading}</H4>

            <RadioGroup onChange={handleChangeMethod} selectedValue={method}>
              <Radio large label={st.classificationLabel} value="cls" />
              <Radio large label={st.regressionLabel} value="reg" />
            </RadioGroup>
          </PredictorContainer>
          <Popover
            isOpen={!formAlert.predictionVariable}
            onOpened={() =>
              setTimeout(() => setFormAlert(ModelConfigCheckDefault), 1200)
            }
          >
            <PredictorContainer>
              <H4>{st.predictionHeading}</H4>
              <PredictorInput
                large
                placeholder={'Search..'}
                onChange={(input: React.ChangeEvent<HTMLInputElement>) =>
                  setPredictorQuery(input.target.value)
                }
                leftIcon={'search'}
              />
              <PredictorVariablesList
                columns={trimmedColumn}
                predictorQuery={predictorQuery}
                selected={predictors}
                onSelectPredictor={onSelectPredictor}
                {...{ idVariable, responseVariable }}
              />
              <ButtonPrimary onClick={selectAll}>{'Select All'}</ButtonPrimary>
              <ButtonPrimary onClick={selectNone}>
                {'Select None'}
              </ButtonPrimary>
              <ButtonPrimary onClick={selectToggle}>{'Toggle'}</ButtonPrimary>
            </PredictorContainer>
            <AlertBox>
              <AlertText>{st.predictionAlert}</AlertText>
            </AlertBox>
          </Popover>
        </SettingsContainer>
      </ConfigureContainer>
    </>
  )
}

export const ModelConfigure = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    datasetModule: modules.datasets,
    sitkaState: state
  }
})(Component)

interface predictorProps {
  readonly columns: Array<string>
  readonly predictorQuery: string
  readonly selected: ReadonlyArray<string>
  readonly idVariable: string
  readonly responseVariable: string
  readonly onSelectPredictor: (name: string) => void
}

const PredictorVariablesList: React.FC<predictorProps> = ({
  columns,
  predictorQuery,
  selected,
  onSelectPredictor,
  idVariable,
  responseVariable
}) => {
  const {
    PredictorList,
    PredictorListIdVariable,
    PredictorListPredictors,
    PredictorListUnchecked,
    PredictorListResponse
  } = configure_model_styles
  const predictors = columns.filter(item =>
    item.toLowerCase().includes(predictorQuery.toLowerCase())
  )

  return (
    <PredictorList>
      {predictors.map(item => {
        const checked = selected.includes(item)
        const Checkbox =
          item === idVariable
            ? PredictorListIdVariable
            : item === responseVariable
            ? PredictorListResponse
            : checked
            ? PredictorListPredictors
            : PredictorListUnchecked
        return (
          <Checkbox
            large
            checked={checked}
            onChange={() => onSelectPredictor(item)}
            key={'Checkbox' + item}
          >
            {highlightText(item, predictorQuery)}
          </Checkbox>
        )
      })}
    </PredictorList>
  )
}

const renderMenuItem: ItemRenderer<string> = (
  idVar: string,
  { handleClick, modifiers, query }
) => (
  <MenuItem
    key={uuid.v4()}
    text={highlightText(idVar, query)}
    onClick={handleClick}
  />
)

const highlightText = (text: string, query: string) => {
  let lastIndex = 0
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars)
  if (words.length === 0) {
    return [text]
  }
  const regexp = new RegExp(words.join('|'), 'gi')
  const tokens: React.ReactNode[] = []
  while (true) {
    const match = regexp.exec(text)
    if (!match) {
      break
    }
    const length = match[0].length
    const before = text.slice(lastIndex, regexp.lastIndex - length)
    if (before.length > 0) {
      tokens.push(before)
    }
    lastIndex = regexp.lastIndex
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>)
  }
  const rest = text.slice(lastIndex)
  if (rest.length > 0) {
    tokens.push(rest)
  }
  return tokens
}

const escapeRegExpChars = (text: string) => {
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
}
