export interface itemProperty {
  [key: string]: string & number
}

export interface BaseMap<T> {
  [key: string]: T & itemProperty
}

export interface BaseRepository<T> {
  readonly items: BaseMap<T>
  readonly selected: string
  readonly sort: ReadonlyArray<string>
}

export interface ResponseObject<T> {
  readonly id: string
}

export const defaultRepository = <T>(): BaseRepository<T> => ({
  items: {},
  sort: [],
  selected: ''
})

export const parseArrayToBaseRepo = <T>(
  dataArray: Array<any>
): BaseRepository<T> => {
  let items: BaseMap<T> = {}
  let sort: Array<string> = []

  for (let i = 0; i < dataArray.length; i++) {
    items[dataArray[i].id] = dataArray[i]
    sort.push(dataArray[i].id)
  }

  return {
    items: items,
    selected: '',
    sort: sort
  }
}

export const parseArrayToExistingBaseRepo = <T>(
  dataArray: Array<any>,
  oldRepository: BaseRepository<T>
): BaseRepository<T> => {
  let items: BaseMap<T> = {}
  let sort: Array<string> = []

  for (let i = 0; i < dataArray.length; i++) {
    items[dataArray[i].id] = dataArray[i]
    sort.push(dataArray[i].id)
  }

  for (let i = 0; i < oldRepository.sort.length; i++) {
    const id = oldRepository.sort[i]
    if (!sort.includes(oldRepository.items[id].id)) {
      items[oldRepository.items[id].id] = oldRepository.items[id]
      sort.push(oldRepository.items[id].id)
    }
  }

  return {
    items: items,
    selected: '',
    sort: sort
  }
}

export const updateBaseRepoElements = <T>(
  dataArray: Array<any>,
  oldRepository: BaseRepository<T>
): BaseRepository<T> => {
  let items: BaseMap<T> = {}
  let sort: Array<string> = []

  for (let i = 0; i < oldRepository.sort.length; i++) {
    const id = oldRepository.sort[i]
    items[oldRepository.items[id].id] = oldRepository.items[id]
    sort.push(oldRepository.items[id].id)
  }

  for (let i = 0; i < dataArray.length; i++) {
    items[dataArray[i].id] = dataArray[i]
  }

  return {
    items: items,
    selected: '',
    sort: sort
  }
}

const copyBaseRepo = <T>(sourceRepo: BaseRepository<T>): BaseRepository<T> => {
  let items: BaseMap<T> = {}
  let sort: Array<string> = []

  for (let i = 0; i < sourceRepo.sort.length; i++) {
    const id = sourceRepo.sort[i]
    items[sourceRepo.items[id].id] = sourceRepo.items[id]
    sort.push(sourceRepo.items[id].id)
  }
  return {
    items: items,
    selected: '',
    sort: sort
  }
}

export const removeFromBaseRepo = <T>(
  removeId: string,
  oldRepository: BaseRepository<T>
): BaseRepository<T> => {
  let items: BaseMap<T> = {}
  let sort: Array<string> = []

  for (let i = 0; i < oldRepository.sort.length; i++) {
    const id = oldRepository.sort[i]
    if (id !== removeId) {
      items[oldRepository.items[id].id] = oldRepository.items[id]
      sort.push(oldRepository.items[id].id)
    }
  }
  return {
    items: items,
    selected: '',
    sort: sort
  }
}

export const sortBaseRepoByDate = <T>(
  baseRepo: BaseRepository<T>,
  dateName: string
): BaseRepository<T> => {
  let newSort: Array<string> = []
  newSort = [...baseRepo.sort]
  newSort.sort((a, b) => {
    return (
      Date.parse(baseRepo.items[b][dateName]) -
      Date.parse(baseRepo.items[a][dateName])
    )
  })
  return { ...baseRepo, sort: newSort }
}
