import React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'common/redux/sitka/sitka_octain'
import { H4, H2 } from 'ui/styledComponents/constants'
import results_styles from 'ui/styledComponents/results_styles'

interface Props {
  readonly sitkaState: AppState
}

const Component: React.FC<Props> = ({
  sitkaState: {
    models: {
      modelResults: {
        files: { 'tree.png': tree},
        state
      },
    }
  }
}) => {
  const { ErrorContainer } = results_styles
  const { TreeContainer } = results_styles.ModelResults
  if (state !== 'DONE') {
    return (
      <ErrorContainer>
        <H2>Error Loading Artifact</H2>
      </ErrorContainer>
    )
  }

  return (
    <TreeContainer>
      <H2>Simple Decision Tree</H2>
      <p>Intended for intuition only; typically decision trees are poor predictors. (Note: actual class assignment in each terminal node).</p>
      <img src={tree} alt={'Tree'} />
    </TreeContainer>
  )
}

export const ModelResultsTree = connect((state: AppState) => {
  return {
    sitkaState: state
  }
})(Component)
