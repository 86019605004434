import React from 'react'
import { StyledTable, H2 } from 'ui/styledComponents/constants'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import { FetchStatus } from 'enums'
import uuid from 'uuid'
import results_styles from 'ui/styledComponents/results_styles'

interface Props {
  readonly table: Array<Array<string>>
  readonly status: FetchStatus
}

const ModelResultsTable: React.FC<Props> = ({
  table,
  status: loadStatus,
  children
}) => {
  const { LoadingSpinner, ErrorContainer } = results_styles
  const loading = <LoadingSpinner />
  const done = (
    <StyledTable>
      <thead>
        <tr>
          {table && table[0].map(item => <th key={uuid.v4()}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {table &&
          table.slice(1).map(row => <TableRow row={row} key={uuid.v4()} />)}
      </tbody>
    </StyledTable>
  )
  const none = loading
  const error = (
    <ErrorContainer>
      <H2>Error Loading Artifact</H2>
    </ErrorContainer>
  )

  const status =
    loadStatus === FetchStatus.done && !table ? FetchStatus.error : loadStatus

  return (
    <>
      {children}
      <LoadingWrapper {...{ done, error, loading, status, none }} />
    </>
  )
}

interface TableRowProps {
  readonly row: Array<string>
}

const TableRow: React.FC<TableRowProps> = ({ row }) => {
  return (
    <tr>
      {row.map(item => (
        <td key={uuid.v4()}>{item}</td>
      ))}
    </tr>
  )
}

export { ModelResultsTable }
