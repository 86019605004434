import React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'common/redux/sitka/sitka_octain'
import { PredictionResultsTable } from 'ui/components/predictions/prediction_results/prediction_results_table'
import { H2, SLink } from 'ui/styledComponents/constants'
import { FetchStatus } from 'enums'
import { Button, ButtonPrimary } from 'ui/styledComponents/buttons'
import results_styles from 'ui/styledComponents/results_styles'
import { strings } from 'ui/components/strings'

interface Props {
  readonly sitkaState: AppState
}

const Component: React.FC<Props> = ({
  sitkaState: {
    predictions: {
      predictionResults: {
        createdAt,
        idVariable,
        predictionVariables,
        responseVariable,
        method,
        modelId,
        modelName,
        datasetName
      },
      predictionResultsStatus
    }
  }
}) => {
  const { ErrorContainer } = results_styles
  const { ModelButton } = results_styles.PredictionResults
  if (predictionResultsStatus !== FetchStatus.done) {
    return (
      <ErrorContainer>
        <H2>Error Loading Artifact</H2>
      </ErrorContainer>
    )
  }

  const modelLink = (
    <SLink to={`/models/${modelId}`}>
      <ModelButton>{modelName}</ModelButton>
    </SLink>
  )

  const parametersTable = [
    ['Parameter', 'Value'],
    ['Model', modelLink],
    ['Dataset', datasetName],
    ['Id Variable', idVariable],
    ['Response Variable', responseVariable],
    ['Method', strings.modelMethod(method)],
    ['Predictor Variables', predictionVariables],
    ['Created On', createdAt]
  ]

  return (
    <PredictionResultsTable
      table={parametersTable}
      status={predictionResultsStatus}
    />
  )
}

export const PredictionResultsParameters = connect((state: AppState) => {
  return {
    sitkaState: state
  }
})(Component)
