import React, { useState } from 'react'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { DatasetModule } from 'common/redux/sitka/octain_dataset/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import { H4, PageHeadingContainer, H2 } from 'ui/styledComponents/constants'
import { ButtonPrimary } from 'ui/styledComponents/buttons'
import prediction_preview_style from 'ui/components/predictions/prediction_create/prediction_preview_style'
import { useHistory } from 'react-router'

import {
  PredictionWizard,
  predictionWizardStage
} from 'common/redux/sitka/current_session/core'
import { Popover, InputGroup } from '@blueprintjs/core'
import { PreviewList } from 'ui/components/predictions/prediction_create/preview_list'
import predictions_styles from 'ui/components/predictions/predictions_styles'

interface Props {
  readonly currentSession: CurrentSessionModule
  readonly datasetModule: DatasetModule
  readonly sitkaState: AppState
  readonly wizard: PredictionWizard
  readonly setWizard: React.Dispatch<React.SetStateAction<PredictionWizard>>
}

const Component: React.FC<Props> = ({
  wizard,
  setWizard,
  currentSession,
  sitkaState: {
    datasets: { datasets },
    models: { models }
  }
}) => {
  const history = useHistory()
  const [formAlert, setFormAlert] = useState<boolean>(false)
  const st = strings.modelConfigure
  const {
    ButtonContainer,
    AlertBox,
    AlertText,
    PredictionNameContainer,
    PreviewContainer
  } = prediction_preview_style
  const { PredictionsHeadingIcon } = predictions_styles
  const { datasetId, modelId } = wizard

  const createPrediction = () => {
    const name = wizard.predictionName === ''
    if (name) {
      setFormAlert(true)
      return
    }
    currentSession.handleCreatePrediction(wizard)
    setWizard({
      ...wizard,
      stage: predictionWizardStage.PROCESSING
    })
    history.push('/predictions/new/processing')
  }

  const predictionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWizard({
      ...wizard,
      predictionName: event.target.value
    })
  }

  const dataset = datasets.items[datasetId]

  const model = models.items[modelId]

  return (
    <>
      <PageHeadingContainer>
        <H2>
          <PredictionsHeadingIcon />
          {'Create Prediction - Review'}
        </H2>
        <ButtonPrimary onClick={createPrediction}>Predict</ButtonPrimary>
      </PageHeadingContainer>
      <ButtonContainer>
        <PredictionNameContainer>
          <H4>{'Prediction Name'}</H4>
          <Popover
            isOpen={formAlert}
            onOpened={() => setTimeout(() => setFormAlert(false), 1200)}
          >
            <InputGroup
              large
              onChange={predictionNameChange}
              placeholder={'Prediction Name..'}
            />
            <AlertBox>
              <AlertText>{'Prediction Name Required'}</AlertText>
            </AlertBox>
          </Popover>
        </PredictionNameContainer>
      </ButtonContainer>
      <H4>{st.previewHeading}</H4>
      <PreviewContainer>
        <PreviewList {...{ dataset, model }} />
      </PreviewContainer>
    </>
  )
}

export const PredictionPreview = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    datasetModule: modules.datasets,
    sitkaState: state
  }
})(Component)
