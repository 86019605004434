import React, { useState } from 'react'
import {
  OctainDatasets,
  OctainDataset,
  UploadStatus
} from 'common/redux/sitka/octain_dataset/core'
import { strings } from 'ui/components/strings'
import { H3, H1, StyledTable, H2, H4 } from 'ui/styledComponents/constants'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import { PageButtons } from 'ui/components/page-buttons/page-buttons'
import dataset_list_styles from 'ui/components/models/model_create/dataset_list_styles'
import { FetchStatus } from 'enums'
import { ProgressBar } from '@blueprintjs/core'
import { BaseRepository } from 'common/redux/sitka/basemap'

interface DatasetListProps {
  datasets: OctainDatasets
  status: FetchStatus
  uploadStatus: BaseRepository<UploadStatus>
  onSelectItem: (id: string) => void
}

const DatasetList: React.FC<DatasetListProps> = ({
  status,
  datasets,
  onSelectItem,
  uploadStatus
}) => {
  const [page, setPage] = useState(0)
  const resultsPerPage = 13

  const displaySort = datasets
    ? datasets.sort.slice(page * resultsPerPage, (page + 1) * resultsPerPage)
    : []
  const totalPages = Math.ceil(
    (datasets.sort.length + uploadStatus.sort.length) / resultsPerPage
  )
  const loadingDisplaySort = uploadStatus.sort.slice(
    page * resultsPerPage,
    (page + 1) * resultsPerPage
  )

  const {
    common: { networkError },
    dataList: { emptyDatasets, columnName }
  } = strings
  const {
    EmptyDatalistContainer,
    LoadingSpinner,
    PageButtonContainer,
    DatalistContainer,
    UploadProgress
  } = dataset_list_styles

  const loading = (
    <EmptyDatalistContainer>
      <LoadingSpinner />
    </EmptyDatalistContainer>
  )

  const none = loading

  const error = (
    <EmptyDatalistContainer>
      <H1>{networkError}</H1>
    </EmptyDatalistContainer>
  )

  const done =
    status === FetchStatus.done &&
    datasets.sort.length === 0 &&
    uploadStatus.sort.length === 0 ? (
      <EmptyDatalistContainer>
        <H3>{emptyDatasets}</H3>
      </EmptyDatalistContainer>
    ) : (
      <DatalistContainer>
        <StyledTable>
          <thead>
            <tr>
              {/* TODO: Refactor datalist columns */}
              <th>{columnName}</th>
              <th>{'Created At'}</th>
            </tr>
          </thead>
          <tbody>
            {loadingDisplaySort.map(id => (
              <LoadingTableRow key={id} uploadStatus={uploadStatus.items[id]} />
            ))}

            {displaySort.map(id => (
              <TableRow
                key={id}
                octainDataset={datasets.items[id]}
                onClick={onSelectItem}
              />
            ))}
          </tbody>
        </StyledTable>
      </DatalistContainer>
    )

  return (
    <>
      <LoadingWrapper {...{ done, loading, error, none, status }} />
      <PageButtonContainer>
        <PageButtons {...{ page, setPage, totalPages }} />
      </PageButtonContainer>
    </>
  )
}

export { DatasetList }

interface TableRowProps {
  octainDataset: OctainDataset
  onClick: (id: string) => void
}

const TableRow: React.FC<TableRowProps> = ({ octainDataset, onClick }) => {
  const { name, createdAt, id } = octainDataset
  return (
    <tr onClick={() => onClick(id)}>
      <td>{name}</td>
      <td>{createdAt}</td>
    </tr>
  )
}

interface LoadingTableRowProps {
  readonly uploadStatus: UploadStatus
}

const LoadingTableRow: React.FC<LoadingTableRowProps> = ({
  uploadStatus: { name, progress, size, state }
}) => {
  return state !== FetchStatus.done ? (
    <tr>
      <td>{name}</td>
      <td>
        <ProgressBar intent={'success'} value={progress / size} />
        <div>
          {progress / size >= 0.99
            ? 'Server Processing'
            : (progress / 1e6).toFixed(2) +
              '/' +
              (size / 1e6).toFixed(2) +
              ' MB'}
        </div>
      </td>
    </tr>
  ) : (
    <></>
  )
}
