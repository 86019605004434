import axios, { AxiosResponse, AxiosError } from 'axios'
import { mockDataset, mockModel, mockSessionData } from 'common/mockData'
import {
  ModelWizard,
  PredictionWizard
} from 'common/redux/sitka/current_session/core'
import {
  CreateModelRemote,
  CreatePredictionRemote
} from 'common/redux/sitka/current_session/remote'
import { eventChannel, buffers, END } from 'redux-saga'
import {
  getDatasetsParams,
  patchDatasetsParams,
  patchPredictionsParams,
  getPredictionsParams,
  getModelsParams,
  patchUserParams
} from 'common/request_shape'
import { NewUserWizard } from 'common/redux/sitka/admin/core'

const USE_MOCK = process.env.REACT_APP_USE_MOCK === 'true'
const API_HOST = process.env.REACT_APP_API_HOST
const LOGGING = process.env.REACT_APP_LOGGING === 'true'

axios.defaults.baseURL = API_HOST

export interface ResponseModel {
  data?: any
  status: number
  statusText: string
}

const handleResp = (resp: AxiosResponse): ResponseModel => {
  if (LOGGING) console.log(resp)
  if (resp.status !== 200) {
    return { status: resp.status, statusText: resp.statusText }
  }

  resp.headers['x-jwt-token'] &&
    localStorage.setItem('octainSessionToken', resp.headers['x-jwt-token'])

  return {
    data: resp.data,
    status: resp.status,
    statusText: resp.statusText
  }
}

const handleErrorResp = (resp: AxiosError): ResponseModel => {
  if (LOGGING) console.log(resp.response)
  if (!resp.response) {
    return { status: 404, statusText: 'Network Error' }
  }

  return {
    status: resp.response.status,
    statusText: resp.response.statusText
  }
}

enum ContentType {
  'application/json;charset=UTF-8',
  'multipart/form-data'
}

const buildHeader = (
  type: ContentType = ContentType['application/json;charset=UTF-8']
) => {
  return {
    Authorization: `Bearer ${localStorage.getItem('octainSessionToken')}`,
    'Content-Type': ContentType[type]
  }
}

// Datasets
export const getDatasets = (
  params: getDatasetsParams
): Promise<ResponseModel> => {
  if (USE_MOCK) {
    return mockDataset
  }

  return axios
    .get<ResponseModel>(`/datasets`, {
      params: params,
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const createDatasetUploadChannel = (data: FormData) => {
  return eventChannel(emitter => {
    const onProgress = (event: any) => {
      emitter({ event })
    }

    const onError = (error: any) => {
      emitter({ error: handleErrorResp(error) })
      return handleErrorResp(error)
    }

    const onSuccess = (resp: AxiosResponse<ResponseModel>) => {
      emitter({ success: handleResp(resp) })
      return handleResp(resp)
    }

    axios
      .put<ResponseModel>(`/datasets`, data, {
        onUploadProgress: onProgress,
        headers: buildHeader(ContentType['multipart/form-data'])
      })
      .then(onSuccess)
      .catch(onError)

    return () => {}
  }, buffers.sliding(2))
}

export const patchDataset = (
  id: string,
  params: patchDatasetsParams
): Promise<ResponseModel> => {
  return axios
    .patch(`/datasets/${id}`, params, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

// Models
export const createModel = (
  modelSettings: ModelWizard
): Promise<ResponseModel> => {
  const new_model: CreateModelRemote = {
    dataset_id: parseInt(modelSettings.datasetId),
    id_variable: modelSettings.idVariable,
    method: modelSettings.method,
    name: modelSettings.modelName,
    prediction_variables: modelSettings.predictors,
    response_variable: modelSettings.responseVariable,
    training_time: parseInt(modelSettings.trainingTime)
  }
  return axios
    .post<ResponseModel>(`/models`, new_model, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getOctainModels = (
  params: getModelsParams
): Promise<ResponseModel> => {
  if (USE_MOCK) {
    return mockModel
  }

  return axios
    .get('/models', {
      params: params,
      headers: buildHeader(ContentType['application/json;charset=UTF-8'])
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getOctainModel = (modelId: string): Promise<ResponseModel> => {
  if (USE_MOCK) {
    return mockModel
  }

  return axios
    .get(`/models/${modelId}`, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const patchModel = (
  id: string,
  new_values: patchPredictionsParams
): Promise<ResponseModel> => {
  return axios
    .patch(`/models/${id}`, new_values, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

// Auth
export const login = (idToken: string): Promise<ResponseModel> => {
  if (USE_MOCK) {
    return mockSessionData
  }

  return axios
    .get(`/login`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: idToken
      }
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const checkAuth = (): Promise<ResponseModel> => {
  return axios
    .get<ResponseModel>(`/auth`, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const get_file = (url: string): Promise<ResponseModel> => {
  return axios
    .get(url)
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

// Predictions
export const createPrediction = (
  predictionSettings: PredictionWizard
): Promise<ResponseModel> => {
  const new_prediction: CreatePredictionRemote = {
    name: predictionSettings.predictionName,
    dataset_id: parseInt(predictionSettings.datasetId),
    model_id: parseInt(predictionSettings.modelId)
  }
  return axios
    .post<ResponseModel>(`/predictions`, new_prediction, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getPredictions = (
  params: getPredictionsParams
): Promise<ResponseModel> => {
  if (USE_MOCK) {
    // TODO: return mock prediction
  }

  return axios
    .get('/predictions', {
      params: params,
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getPrediction = (predictionId: string): Promise<ResponseModel> => {
  if (USE_MOCK) {
    // TODO: return mock prediction
  }

  return axios
    .get(`/predictions/${predictionId}`, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const patchPrediction = (
  id: string,
  params: patchPredictionsParams
): Promise<ResponseModel> => {
  return axios
    .patch(`/predictions/${id}`, params, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

// Admin

export const createUser = (user: NewUserWizard): Promise<ResponseModel> => {
  return axios
    .post(`/users`, user, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const getUsers = (): Promise<ResponseModel> => {
  return axios
    .get(`/users`, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}

export const patchUser = (
  id: string,
  params: patchUserParams
): Promise<ResponseModel> => {
  return axios
    .patch(`/users/${id}`, params, {
      headers: buildHeader()
    })
    .then(resp => handleResp(resp))
    .catch(handleErrorResp)
}
