import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { DatasetModule } from 'common/redux/sitka/octain_dataset/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import {
  H3,
  H4,
  H1,
  StyledTable,
  PageHeadingContainer,
  H2
} from 'ui/styledComponents/constants'
import { useHistory } from 'react-router'
import prediction_model_styles from 'ui/components/predictions/prediction_create/prediction_model_styles'
import {
  PredictionWizard,
  predictionWizardDefault,
  predictionWizardStage
} from 'common/redux/sitka/current_session/core'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import { PageButtons } from 'ui/components/page-buttons/page-buttons'
import { OctainModel } from 'common/redux/sitka/octain_model/core'
import { FetchStatus } from 'enums'
import { itemProperty } from 'common/redux/sitka/basemap'
import { ButtonPrimary } from 'ui/styledComponents/buttons'
import predictions_styles from 'ui/components/predictions/predictions_styles'

interface PredictionModelProps {
  readonly currentSession: CurrentSessionModule
  readonly datasetModule: DatasetModule
  readonly sitkaState: AppState
  readonly wizard: PredictionWizard
  readonly setWizard: React.Dispatch<React.SetStateAction<PredictionWizard>>
}

const Component: React.FC<PredictionModelProps> = ({
  wizard,
  setWizard,
  currentSession,
  datasetModule,
  sitkaState: {
    models: { models, status }
  }
}) => {
  const [page, setPage] = useState(0)
  const resultsPerPage = 13

  const modelSort = models.sort.filter(
    (item: string) => models.items[item].state === 'DONE'
  )

  const displaySort = models
    ? modelSort.slice(page * resultsPerPage, (page + 1) * resultsPerPage)
    : []
  const totalPages = Math.ceil(modelSort.length / resultsPerPage)

  const history = useHistory()

  useEffect(() => {
    setWizard(predictionWizardDefault)
  }, [])

  const onSelectItem = (id: string) => {
    setWizard({
      ...wizard,
      modelId: id,
      stage: predictionWizardStage.CHOOSE_DATASET
    })
    history.push('/predictions/new/dataset')
  }

  const {
    DatasetContainer,
    EmptyDatalistContainer,
    LoadingSpinner,
    PageButtonContainer,
    DatalistContainer
  } = prediction_model_styles
  const { PredictionsHeadingIcon } = predictions_styles
  const loading = (
    <EmptyDatalistContainer>
      <LoadingSpinner />
    </EmptyDatalistContainer>
  )
  const none = loading

  const error = (
    <EmptyDatalistContainer>
      <H1>Network Error</H1>
    </EmptyDatalistContainer>
  )

  const done =
    status === FetchStatus.done && modelSort.length === 0 ? (
      <EmptyDatalistContainer>
        <H3>No Datasets</H3>
      </EmptyDatalistContainer>
    ) : (
      <DatalistContainer>
        <StyledTable>
          <thead>
            <tr>
              {/* TODO: Refactor datalist columns */}
              <th>{'Model Name'}</th>
              <th>{'Created At'}</th>
              <th>{'Accuracy'}</th>
            </tr>
          </thead>
          <tbody>
            {displaySort.map(id => (
              <TableRow
                key={id}
                octainModel={models.items[id]}
                onClick={() => onSelectItem(id)}
              />
            ))}
          </tbody>
        </StyledTable>
      </DatalistContainer>
    )
  return (
    <>
      <PageHeadingContainer>
        <H2>
          <PredictionsHeadingIcon />
          {'Create Prediction - Select Model'}
        </H2>
      </PageHeadingContainer>

      <DatasetContainer>
        <H4>Select Model For Prediction</H4>
        <LoadingWrapper {...{ done, loading, error, none, status }} />
        <PageButtonContainer>
          <PageButtons {...{ page, setPage, totalPages }} />
        </PageButtonContainer>
      </DatasetContainer>
    </>
  )
}

export const PredictionModel = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    datasetModule: modules.datasets,
    sitkaState: state
  }
})(Component)

interface TableRowProps {
  octainModel: OctainModel
  onClick: (id: string) => void
}

const TableRow: React.FC<TableRowProps> = ({ octainModel, onClick }) => {
  const { name, createdAt, id, accuracy } = octainModel
  return (
    <tr onClick={() => onClick(id)}>
      <td>{name}</td>
      <td>{createdAt}</td>
      <td>{accuracy}%</td>
    </tr>
  )
}
