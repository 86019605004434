import React from 'react'
import { H4 } from 'ui/styledComponents/constants'
import { strings } from 'ui/components/strings'
import home_styles from 'ui/components/home/home_styles'

const Home: React.FC = () => {
  const {
    home: { modelHeading, modelBody, predictHeading, predictBody }
  } = strings

  const { Container, Card, ModelIcon, PredictIcon, CardLink } = home_styles

  return (
    <Container>
      <CardLink to={'/models'}>
        <Card>
          <H4>
            <ModelIcon /> {modelHeading}
          </H4>
          <p>{modelBody}</p>
        </Card>
      </CardLink>
      <CardLink to={'/predictions'}>
        <Card>
          <H4>
            <PredictIcon />
            {predictHeading}
          </H4>
          <p>{predictBody}</p>
        </Card>
      </CardLink>
    </Container>
  )
}

export { Home }
