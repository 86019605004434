import React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'common/redux/sitka/sitka_octain'
import { ModelResultsTable } from 'ui/components/models/model_results/model_results_table'
import { H4, H2 } from 'ui/styledComponents/constants'
import { FetchStatus } from 'enums'
import results_styles from 'ui/styledComponents/results_styles'

interface Props {
  readonly sitkaState: AppState
}

const Component: React.FC<Props> = ({
  sitkaState: {
    models: {
      modelResults: {
        tp,
        tn,
        fp,
        fn,
        precision,
        recall,
        tbl,
        files: { 'lift_image.png': liftImage }
      },
      modelResultsStatus
    }
  }
}) => {
  const { ErrorContainer } = results_styles
  const {
    ModelRatesContainer,
    ConfusionMatrixContainer,
    TrueFalseHeading,
    TrueFalseContainer,
    GainsCurveImage
  } = results_styles.ModelResults

  if (modelResultsStatus !== FetchStatus.done) {
    return (
      <ErrorContainer>
        <H2>Error Loading Artifact</H2>
      </ErrorContainer>
    )
  }

  return (
    <ModelRatesContainer>
      <ConfusionMatrixContainer>
        <H4>Confusion Matrix</H4>
        (only for classifier models!)
        <ModelResultsTable table={tbl} status={modelResultsStatus} />
        <TrueFalseContainer>
          <TrueFalseHeading>True Positive: {tp}%</TrueFalseHeading>
          <TrueFalseHeading>True Negative: {tn}%</TrueFalseHeading>
          <TrueFalseHeading>False Positive: {fp}%</TrueFalseHeading>
          <TrueFalseHeading>False Negative: {fn}%</TrueFalseHeading>
          <TrueFalseHeading>Precision: {precision}%</TrueFalseHeading>
          <TrueFalseHeading>Recall: {recall}%</TrueFalseHeading>
        </TrueFalseContainer>
      </ConfusionMatrixContainer>
      <GainsCurveImage src={liftImage} alt={'Cumulative Gains Curve'} />
    </ModelRatesContainer>
  )
}

export const ModelResultsRates = connect((state: AppState) => {
  return {
    sitkaState: state
  }
})(Component)
