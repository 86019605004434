import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { authState } from 'common/redux/sitka/current_session/core'
import { OctainNavbar } from 'ui/components/octain_navbar/octain_navbar'
import { Login } from 'ui/login/login'
import { Home } from 'ui/components/home/home'
import { Models } from 'ui/components/models/models'
import { Predictions } from 'ui/components/predictions/predictions'
import { connect } from 'react-redux'
import { ModelCreate } from 'ui/components/models/model_create/model_create'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { PredictionCreate } from 'ui/components/predictions/prediction_create/prediction_create'
import { ModelResults } from 'ui/components/models/model_results/model_results'
import { PredictionResults } from 'ui/components/predictions/prediction_results/prediction_resutls'
import { ContentContainer } from 'ui/styledComponents/constants'
import { Admin } from 'ui/components/admin/admin'

interface AppProps {
  readonly sitkaState: AppState
  readonly currentSession: CurrentSessionModule
}

const Component: React.FC<AppProps> = ({
  currentSession,
  sitkaState: {
    currentSession: {
      auth,
      redirect,
      session: { permissions }
    }
  }
}) => {
  const SaveForRedirect: React.FC = () => {
    const location = useLocation()
    currentSession.handleRedirect(location.pathname)
    return <></>
  }

  const authenticated = auth === authState.AUTHENTICATED
  const unauthenticated = auth === authState.UNAUTHENTICATED
  const processing = auth === authState.PROCESSING
  const tokenCheck = auth === authState.CHECKTOKEN

  const redirectFromState = authenticated && redirect
  const admin = permissions === 'ADMIN'

  if (auth === authState.CHECKTOKEN) {
    currentSession.handleCheckToken()
  }

  if (redirect && authenticated) {
    currentSession.handleClearRedirect()
  }

  return (
    <Router>
      <Switch>
        {redirectFromState && <Redirect to={redirect} />}
        {(tokenCheck || unauthenticated || processing) && (
          <Route path="/login">
            <Login />
          </Route>
        )}

        {unauthenticated && (
          <>
            <SaveForRedirect />
            <Redirect to="/login" />
          </>
        )}
        {authenticated && (
          <Switch>
            <Route path="/home">
              <OctainNavbar />
              <ContentContainer>
                <Home />
              </ContentContainer>
            </Route>

            <Route path="/models/new">
              <OctainNavbar />
              <ContentContainer>
                <ModelCreate />
              </ContentContainer>
            </Route>

            <Route path="/models/:modelId">
              <OctainNavbar />
              <ContentContainer>
                <ModelResults />
              </ContentContainer>
            </Route>

            <Route path="/models">
              <OctainNavbar />
              <ContentContainer>
                <Models />
              </ContentContainer>
            </Route>

            <Route path="/predictions/new">
              <OctainNavbar />
              <ContentContainer>
                <PredictionCreate />
              </ContentContainer>
            </Route>

            <Route path="/predictions/:predictionId">
              <OctainNavbar />
              <ContentContainer>
                <PredictionResults />
              </ContentContainer>
            </Route>

            <Route path="/predictions">
              <OctainNavbar />
              <ContentContainer>
                <Predictions />
              </ContentContainer>
            </Route>

            {admin && (
              <Route path="/admin">
                <OctainNavbar />
                <ContentContainer>
                  <Admin />
                </ContentContainer>
              </Route>
            )}

            <Route path="*">
              <Redirect to="/home" />
            </Route>
          </Switch>
        )}
      </Switch>
    </Router>
  )
}

export const App = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    modelModule: modules.models,
    predictionModule: modules.predictions,
    sitkaState: state
  }
})(Component)
