import styled from 'styled-components'
import { flex, cn } from 'ui/styledComponents/constants'
import { InputGroup, IInputGroupProps, Checkbox, Icon } from '@blueprintjs/core'

export default {
  ButtonContainer: styled.div`
    ${flex.flexCenteredRow}
    justify-content: space-around;
    width: 100%;
    margin-top: 10px;
  `,
  PreviewContainer: styled.div`
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  `,
  PredictorList: styled.div`
    height: 420px;
    overflow-y: auto;
  `,
  PredictionNameContainer: styled.div`
    ${flex.flexCenteredColumn}
  `,
  PredictorListUnchecked: styled(Checkbox)`
    &&&&& {
      height: 30px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin: 2px 4px;
      padding-left: 40px;
    }
  `,
  PredictorListPredictors: styled(Checkbox)`
    &&&&& {
      height: 30px;
      background-color: #137cbd;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin: 2px 4px;
      padding-left: 40px;
      color: white;
    }
  `,
  PredictorListIdVariable: styled(Checkbox)`
    &&&&& {
      height: 30px;
      background-color: #fd7de7;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin: 2px 4px;
      padding-left: 40px;
      color: white;
    }
  `,
  PredictorListResponse: styled(Checkbox)`
    &&&&& {
      height: 30px;
      background-color: #7cfd80;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin: 2px 4px;
      padding-left: 40px;
    }
  `,
  AlertBox: styled.div`
    padding: 4px 30px;    
    width: 100%;
    height 100%;
  `,
  AlertText: styled.h4`
    color: #ef0c0c;
  `
}
