import React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'common/redux/sitka/sitka_octain'
import { H2 } from 'ui/styledComponents/constants'
import results_styles from 'ui/styledComponents/results_styles'

interface Props {
  readonly sitkaState: AppState
}

const Component: React.FC<Props> = ({
  sitkaState: {
    models: {
      modelResults: {
        files: { 'force_plot.html': forcePlot },
        state
      },
    }
  }
}) => {
  const { ErrorContainer } = results_styles

  if (state !== 'DONE') {
    return (
      <ErrorContainer>
        <H2>Error Loading Artifact</H2>
      </ErrorContainer>
    )
  }

  return (
    <iframe
      style={{ width: '100%', height: '100%' }}
      title={'ForcePlot'}
      src={forcePlot}
      frameBorder={0}
      sandbox={'allow-scripts'}
    ></iframe>
  )
}

export const ModelResultsInteractive = connect((state: AppState) => {
  return {
    sitkaState: state
  }
})(Component)
