import { ResponseModel } from 'common/client'
import {
  OctainDataset,
  DatasetTypes
} from 'common/redux/sitka/octain_dataset/core'
import { OctainModel } from 'common/redux/sitka/octain_model/core'

export const mockDataset = new Promise<ResponseModel>((resolve, reject) => {
  let datasets: Array<OctainDataset> = []
  for (let i = 0; i < 10; i++) {
    datasets.push({
      id: `${i}`,
      name: `TestDataset ${i}`,
      type: DatasetTypes.TRAINING,
      columnNames: ['Test1', 'Test2', 'TestA', 'TestB'],
      createdAt: new Date(1000),
      preview: [['column1'], ['column2']],
      archived: false
    })
  }
  setTimeout(
    () => resolve({ data: datasets, status: 200, statusText: '' }),
    600
  )
})

export const mockModel = new Promise<ResponseModel>((resolve, reject) => {
  let models: Array<OctainModel> = []
  for (let i = 0; i < 10; i++) {
    models.push({
      id: `${i}`,
      name: `TestModel ${i}`,
      state: '',
      error: '',
      datasetId: '0',
      trainingTime: '600',
      createdAt: '',
      updatedAt: '',
      accuracy: '24',
      files: {
        'csv_post.csv': '',
        'cv1.csv': '',
        'cv2.csv': '',
        'cv3.csv': '',
        'cv4.csv': '',
        'log.txt': '',
        'sk_log.txt': '',
        'force_plot.html': '',
        'lift_image.png': '',
        'shap_image.png': '',
        'shap_lines.png': '',
        'shap_plots.csv': '',
        'tree.png': '',
        'top20_image.png': '',
        model: '',
        ohe: ''
      },
      fn: '0',
      fp: '0',
      idVariable: 'id',
      method: 'cls',
      partialDependence: '',
      precision: '0',
      predictionVariables: '20',
      recall: '2',
      responseVariable: '',
      tbl: [['']],
      tn: '',
      top20: [['']],
      tp: '',
      archived: false,
      datasetName: ''
    })
  }
  setTimeout(() => resolve({ data: models, status: 200, statusText: '' }), 600)
})

export const mockSessionData = new Promise<ResponseModel>((resolve, reject) => {
  setTimeout(
    resolve({
      data: {
        errorText: '',
        sessionToken: '131bdde2-1d9c-4e41-9b83-4d5618b67f9a'
      },
      status: 200,
      statusText: ''
    }),
    600
  )
})
