import React from 'react'
import { getPageButtons } from 'ui/components/page-buttons/util'
import page_buttons_styles from 'ui/components/page-buttons/page_buttons_styles'

export interface PageButtonsProps {
  readonly page?: number
  readonly totalPages?: number
  readonly setPage?: (page: number) => void
}

export const PageButtons: React.FC<PageButtonsProps> = ({
  page = 0,
  setPage = () => {},
  totalPages = 0
}) => {
  const pageButtons: Array<number> = getPageButtons(page, totalPages)

  const {
    ButtonGroup,
    LeftButton,
    RightButton,
    NumberButton
  } = page_buttons_styles

  const PageButtonList = pageButtons.map((pageId: number) => (
    <NumberButton
      key={pageId + 'pageButton'}
      onClick={() => setPage(pageId)}
      active={pageId === page}
    >
      {pageId + 1}
    </NumberButton>
  ))

  const decreasePage = () => (page > 0 ? setPage(page - 1) : '')
  const increasePage = () => (page < totalPages ? setPage(page + 1) : '')

  return (
    <ButtonGroup>
      <LeftButton disabled={page === 0} onClick={decreasePage} />
      {PageButtonList}
      <RightButton
        disabled={page >= totalPages - 1}
        onClick={increasePage}
      ></RightButton>
    </ButtonGroup>
  )
}
