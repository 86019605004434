import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { useParams } from 'react-router'
import { ModelModule } from 'common/redux/sitka/octain_model/module'
import {
  Container,
  H2,
  H3,
  PageHeadingContainer,
  H4,
  RefreshButton
} from 'ui/styledComponents/constants'
import { ModelResultsTable } from 'ui/components/models/model_results/model_results_table'
import { ModelLog } from 'ui/components/models/model_results/model_results_log'
import { ModelResultsImportance } from 'ui/components/models/model_results/model_results_importance'
import { ModelResultsRates } from 'ui/components/models/model_results/model_results_rates'
import { ModelResultsShap } from 'ui/components/models/model_results/model_results_shap'
import { ModelResultsInteractive } from 'ui/components/models/model_results/model_results_interactive'
import { ModelResultsTree } from 'ui/components/models/model_results/model_results_tree'
import { strings } from 'ui/components/strings'
import { FetchStatus } from 'enums'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import { ModelResultsParameters } from 'ui/components/models/model_results/model_results_parameters'
import results_styles from 'ui/styledComponents/results_styles'
import model_styles from 'ui/components/models/model_styles'
import { SmallToggleButton, SmallButton } from 'ui/styledComponents/buttons'
import { Popover, Position } from '@blueprintjs/core'
import { ModelActionMenu } from 'ui/components/models/model_action_menu'

interface Props {
  readonly sitkaState: AppState
  readonly modelModule: ModelModule
}

const Component: React.FC<Props> = ({
  sitkaState: {
    models: { modelResults, modelResultsStatus }
  },
  modelModule
}) => {
  const {
    PageBody,
    PageButtonInactive,
    PageButtonActive,
    PageContainer,
    Tab,
    PageCategoryHeading,
    PageBodyContainer,
    LoadingSpinner,
    PageHeading,
    PageBodyCentered,
    ActionMenuIcon
  } = results_styles
  const { ModelHeadingIcon } = model_styles
  const {
    cv1,
    cv2,
    cv3,
    log,
    cv1Status,
    cv2Status,
    cv3Status,
    logStatus
  } = modelResults.fileData
  const { pageHeading, pageSubHeading } = strings.modelResults
  const { name, createdAt, accuracy, state, id, archived } = modelResults
  const { PageButtonContainer } = model_styles

  const [pageSelect, setPageSelect] = useState<string>('Parameters')
  const { modelId } = useParams()

  useEffect(() => {
    if (modelId) {
      modelModule.handleGetModelResults(modelId)
    }
  }, [])

  // Preload images
  useEffect(() => {
    if (modelResults.state === 'DONE') {
      new Image().src = modelResults.files['top20_image.png']
      new Image().src = modelResults.files['lift_image.png']
      new Image().src = modelResults.files['shap_image.png']
      new Image().src = modelResults.files['shap_lines.png']
      new Image().src = modelResults.files['tree.png']
    }
  }, [modelResults.files, modelResults.state])

  const PageComponent =
    pageSelect === 'Logs' ? (
      <ModelLog log={log} status={logStatus} />
    ) : pageSelect === 'Model Candidates' ? (
      <ModelResultsTable table={cv1} status={cv1Status} />
    ) : pageSelect === 'Parameters' ? (
      <ModelResultsParameters />
    ) : pageSelect === 'Ensemble' ? (
      <ModelResultsTable table={cv2} status={cv2Status} />
    ) : pageSelect === 'Results' ? (
      <ModelResultsTable table={cv3} status={cv3Status} />
    ) : pageSelect === 'Importance' ? (
      <ModelResultsImportance />
    ) : pageSelect === 'Interactive SHAP' ? (
      <ModelResultsInteractive />
    ) : pageSelect === 'Rates & Gains' ? (
      <ModelResultsRates />
    ) : pageSelect === 'SHAP Plots' ? (
      <ModelResultsShap />
    ) : pageSelect === 'Decision Tree' ? (
      <ModelResultsTree />
    ) : (
      ''
    )

  const ModelHeading = name ? (
    <H2>
      <ModelHeadingIcon />
      {archived ? 'Archived - ' + name : name}
      {accuracy && <> - {accuracy} % - Accuracy</>}
    </H2>
  ) : (
    <div />
  )

  const CreatedAtHeading = name ? (
    <H3>Created On - {createdAt.toString()}</H3>
  ) : (
    <div />
  )

  interface PageButtonProps {
    readonly id: string
  }

  const PageButton: React.FC<PageButtonProps> = ({ id }) => {
    return pageSelect === id ? (
      <PageButtonActive onClick={() => setPageSelect(id)}>
        {id}
      </PageButtonActive>
    ) : (
      <PageButtonInactive onClick={() => setPageSelect(id)}>
        {id}
      </PageButtonInactive>
    )
  }

  const done = (
    <PageBodyContainer>
      <Tab>
        <PageButton id={'Parameters'} />
        <PageButton id={'Model Candidates'} />
        <PageButton id={'Ensemble'} />
        <PageButton id={'Results'} />
        <PageButton id={'Importance'} />
        <PageButton id={'Rates & Gains'} />
        <PageButton id={'SHAP Plots'} />
        <PageButton id={'Interactive SHAP'} />
        <PageButton id={'Decision Tree'} />
        <PageButton id={'Logs'} />
      </Tab>
      <PageBody>{PageComponent}</PageBody>
    </PageBodyContainer>
  )

  const loading = (
    <PageBodyContainer>
      <Tab>
        <PageButton id={'Parameters'} />
        <PageButton id={'Logs'} />
      </Tab>
      <PageBodyCentered>
        <LoadingSpinner />
      </PageBodyCentered>
    </PageBodyContainer>
  )

  const error = (
    <PageBodyContainer>
      <Tab>
        <PageButton id={'Parameters'} />
        <PageButton id={'Logs'} />
      </Tab>
      <PageBody>{PageComponent}</PageBody>
    </PageBodyContainer>
  )
  const none = loading

  const status: FetchStatus =
    (state !== '' && state !== 'DONE') ||
    modelResultsStatus === FetchStatus.error
      ? FetchStatus.error
      : modelResultsStatus

  const getResults = () => {
    if (modelId) {
      modelModule.handleGetModelResults(modelId, true)
    }
  }

  return (
    <Container>
      <PageHeadingContainer>
        {ModelHeading}
        {CreatedAtHeading}
        <PageButtonContainer>
          <SmallToggleButton
            onClick={getResults}
            active={status === FetchStatus.loading}
          >
            <RefreshButton
              processing={status === FetchStatus.loading ? 1 : 0}
            />
          </SmallToggleButton>
          <Popover position={Position.BOTTOM_LEFT}>
            <SmallButton>
              <ActionMenuIcon />
            </SmallButton>
            <ModelActionMenu id={id} model={modelResults} />
          </Popover>
        </PageButtonContainer>
      </PageHeadingContainer>
      <PageContainer>
        <PageCategoryHeading>
          <PageHeading>{pageHeading(pageSelect)}</PageHeading>
          <p>{pageSubHeading(pageSelect)}</p>
        </PageCategoryHeading>
        <LoadingWrapper {...{ done, loading, error, none, status }} />
      </PageContainer>
    </Container>
  )
}

export const ModelResults = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    modelModule: modules.models,
    sitkaState: state
  }
})(Component)
