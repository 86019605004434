import styled from 'styled-components'
import {
  Icon,
  IIconProps,
  InputGroup,
  IInputGroupProps,
  PanelStack,
  Spinner
} from '@blueprintjs/core'
import { flex, padding, margin, cn } from 'ui/styledComponents/constants'

interface FileDropProps {
  readonly drag: boolean
}

export default {
  Body: styled.div.attrs(cn('bp3-dialog-body'))``,
  InputGroup: styled(InputGroup).attrs(
    (): IInputGroupProps => ({ leftIcon: 'pie-chart' })
  )``,
  FileDropContainer: styled.section`
    ${margin.m1}
    height: 130px;
    max-width: 900px;
  `,
  ButtonContainer: styled.div`
    ${flex.flexCenteredRow}
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  `,
  FileDrop: styled.div<FileDropProps>`
    ${flex.flexCenteredColumn}
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
    height: 130px;
    border: 2px dashed grey;
    ${padding.p2}    
    background-color: ${props => (props.drag ? '#accafd' : 'white')};
    h3 {
      ${margin.m1}
    }
    h4 {
      ${margin.m1}
      color: #9291f6;
    }
  `,
  UploadIcon: styled(Icon).attrs(
    (): IIconProps => ({
      icon: 'cloud-upload',
      iconSize: 20
    })
  )``,
  CreatePredictionContainer: styled.div`
    height: 100%;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    overflow: unset;
    & > div {
      overflow: unset;
      border-right: none;
    }
  `,
  LoadingSpinner: styled(Spinner).attrs(() => ({
    className: 'LoadingSpinner',
    size: 100
  }))``,
  Container: styled.div`
    height: calc(100vh - 80px);
    width: 100%;
    overflow-y: auto;
    ${flex.flexCenteredColumn}
    ${padding.p2}
    color: #010149;
  `
}
