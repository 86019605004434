import React from 'react'
import { FetchStatus } from 'enums'
import { LoadingWrapper } from 'ui/components/loading_wrapper'
import results_styles from 'ui/styledComponents/results_styles'

interface Props {
  readonly status: FetchStatus
  readonly log: string
}

const PredictionLog: React.FC<Props> = ({ status, log }) => {
  const { LogBox, EmptyLogBox } = results_styles
  const done = <LogBox>{highlightText(log)}</LogBox>
  const loading = <EmptyLogBox>loading...</EmptyLogBox>
  const error = <EmptyLogBox>no log entries</EmptyLogBox>
  const none = loading
  return <LoadingWrapper {...{ done, loading, error, none, status }} />
}

export { PredictionLog }

const highlightText = (text: string) => {
  let parts = text.split(
    new RegExp('(INFO|ERROR|MODEL_FIT|AUTOSK|SUCCESS|WARNING)', 'gi')
  )
  return (
    <span>
      {parts.map((part, i) => {
        const color =
          part === 'INFO' || part === 'SUCCESS'
            ? '#96fd9a'
            : part === 'ERROR'
            ? '#f97c7c'
            : part === 'WARNING'
            ? '#ffca09'
            : part === 'MODEL_FIT'
            ? '#139dff'
            : part === 'AUTOSK'
            ? '#f7b4ff'
            : '#ccc'
        return (
          <span key={i} style={{ color: color }}>
            {part}
          </span>
        )
      })}
    </span>
  )
}
