import React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'common/redux/sitka/sitka_octain'
import { PredictionResultsTable } from 'ui/components/predictions/prediction_results/prediction_results_table'
import { H2 } from 'ui/styledComponents/constants'
import { FetchStatus } from 'enums'
import results_styles from 'ui/styledComponents/results_styles'

interface Props {
  readonly sitkaState: AppState
}

const Component: React.FC<Props> = ({
  sitkaState: {
    predictions: {
      predictionResults: { preview },
      predictionResultsStatus
    }
  }
}) => {
  const { PageBodyContainer, ErrorContainer } = results_styles

  if (predictionResultsStatus !== FetchStatus.done) {
    return (
      <ErrorContainer>
        <H2>Error Loading Artifact</H2>
      </ErrorContainer>
    )
  }
  return (
    <PageBodyContainer>
      <PredictionResultsTable
        table={preview}
        status={predictionResultsStatus}
      />
    </PageBodyContainer>
  )
}

export const PredictionResultsPreview = connect((state: AppState) => {
  return {
    sitkaState: state
  }
})(Component)
