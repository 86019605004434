interface ErrorCode {
  [key: string]: string
}

const errorMessages: ErrorCode = {
  '401': 'User not authorized'
}

export const strings = {
  common: {
    networkError: 'Network Error'
  },
  models: {
    modelHeading: 'Models',
    archiveModelHeading: 'Models - Archive',
    newModelButton: 'New Model',
    noModels: 'No Models Yet',
    createFirst: 'Create your first model'
  },
  home: {
    modelHeading: 'Model',
    modelBody:
      'Convert a data source with an outcome into a model that can make predictions on new data sources.',
    predictHeading: 'Predict',
    predictBody:
      'Use an existing model to make a prediction based on a new set of data.'
  },
  navBar: {
    heading: 'OCTAIN',
    modelButton: 'Models',
    predictionButton: 'Predictions',
    resultsButton: 'Results',
    logoutButton: 'Logout'
  },
  modelList: {
    columnName: 'NAME',
    columnRuntime: 'RUN LENGTH',
    columnVariance: 'VARIANCE',
    columnActions: 'ACTIONS',
    newPredictionButton: 'New Prediction',
    actionMenu: {
      archive: 'Archive',
      duplicate: 'Duplicate',
      exportText: 'Export'
    }
  },
  modelChooseData: {
    title: 'New Model',
    newModelPlaceholder: 'Model Name...',
    uploadHeading: 'Upload new dataset',
    dragHeadingOver: 'Upload Files',
    dragHeadingNotOver: 'Drag your (csv) dataset here',
    uploadText: 'Click to Upload',
    selectDataset: 'Or select existing dataset',
    cancelButton: 'Cancel',
    backButton: 'Back',
    continueButton: 'Continue',
    processingHeading: 'Building Model..',
    chooseDatasetHeading: 'Choose Dataset'
  },
  dataList: {
    emptyDatasets: 'No datasets',
    columnName: 'Dataset',
    columnDescription: 'Description',
    columnTags: 'Tags'
  },
  errorMessages: errorMessages,
  modelConfigure: {
    previewHeading: 'Dataset Preview',
    nameHeading: 'Model Name',
    namePlaceholder: 'My Model..',
    nameAlertText: 'Model Name Required',
    trainingTimeHeading: 'Training Time (Seconds)',
    trainingTimePlaceholder: '30',
    trainingTimeAlert: 'Training Time must be at least 30',
    idVariable: 'ID Variable',
    idVariableNoResults: 'No Results.',
    idVariableButton: 'ID Variable',
    idVariableAlert: 'Id Variable Required',
    responseVariable: 'Response Variable',
    responseVariableNoResults: 'No Results.',
    responseVariableButton: 'Response Variable',
    responseVariableAlert: 'Response Variable Required',
    trainingMethodHeading: 'Training Method',
    classificationLabel: 'Classification',
    regressionLabel: 'Regression',
    predictionHeading: 'Predictor Variables',
    predictionAlert: 'At least one Predictor Required'
  },
  ProgressState: (state: string) =>
    state === 'NEW'
      ? 'Creating Task'
      : state === 'QUEUED'
      ? 'Queued'
      : state === 'PROCESSING'
      ? 'Processing'
      : state === 'ERROR'
      ? 'Error'
      : state === 'DONE'
      ? 'Finished'
      : '',
  modelResults: {
    pageHeading: (page: string) =>
      page === 'Logs'
        ? 'Logs'
        : page === 'Models'
        ? 'Single Model Accuracy'
        : page === 'Ensemble'
        ? 'Octain Ensemble'
        : page === 'Results'
        ? 'Auto-sklearn Results'
        : page === 'Importance'
        ? 'Top Variables by Relative Importance'
        : page === 'Rates & Gains'
        ? 'Confusion Matrix & Cumulative Gains Curve'
        : page === 'Interactive SHAP'
        ? 'Interactive SHAP Plot'
        : page === 'SHAP Plots'
        ? 'SHAP Plots'
        : page === 'Parameters'
        ? 'Parameters'
        : '',
    pageSubHeading: (page: string) =>
      page === 'Importance'
        ? '(Based on single random forest model, shows power to decrease error)'
        : page === 'SHAP Plots'
        ? '(SHapley Additive exPlanations, 100 cases, randomly selected)'
        : ''
  },
  predictionResults: {
    pageHeading: (page: string) =>
      page === 'Logs'
        ? 'Logs'
        : page === 'Preview'
        ? 'Prediction Preview'
        : page === 'Parameters'
        ? 'Parameters'
        : ''
  },
  modelMethod: (method: string) =>
    method === 'cls' ? 'Classification' : method === 'reg' ? 'Regression' : ''
}
