import { BaseRepository } from 'common/redux/sitka/basemap'
import { FetchStatus } from 'enums'

export enum DatasetTypes {
  'TRAINING',
  'PREDICTION',
  'RESULTS'
}

export interface OctainDataset {
  readonly id: string
  readonly name: string
  readonly createdAt: Date
  readonly type: DatasetTypes
  readonly columnNames: ReadonlyArray<string>
  readonly preview: ReadonlyArray<ReadonlyArray<string>>
  readonly archived: boolean
}

export interface UploadStatus {
  readonly id: string
  readonly name: string
  readonly state: FetchStatus
  readonly progress: number
  readonly size: number
}

export interface OctainDatasets extends BaseRepository<OctainDataset> {}

export interface OctainDatasetState {
  readonly datasets: OctainDatasets
  readonly status: FetchStatus
  readonly uploadStatus: BaseRepository<UploadStatus>
}