import styled from 'styled-components'
import { flex, cn, margin } from 'ui/styledComponents/constants'

export default {
  DatalistContainer: styled.div`
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
      0 1px 1px rgba(16, 22, 26, 0.2);
    border-radius: 5px;
    ${flex.flexCenteredColumn}
    ${margin.m1}
    overflow-x: auto;
  `,
  ModelTable: styled.table.attrs(
    cn(
      'bp3-html-table bp3-html-table-bordered bp3-html-table-condensed bp3-html-table-striped'
    )
  )`
    width: 100%;
    white-space: pre;
  `,
  SelectedCell: styled.td`
    &&&& {
      background-color: #137cbd;
      color: white;
    }
  `,
  IDCell: styled.td`
    &&&& {
      background-color: #fd7de7;
      color: white;
    }
  `,
  ResponseCell: styled.td`
    &&&& {
      background-color: #7cfd80;
    }
  `,
  IndexCell: styled.td`
    &&&& {
      font-weight: 400;
      color: grey;
    }
  `
}
