import styled, { css } from 'styled-components'
import { padding, flex, H4, H2, color } from 'ui/styledComponents/constants'
import { Icon, Spinner } from '@blueprintjs/core'
import { Button } from 'ui/styledComponents/buttons'
const button = css`
  display: block;
  color: black;
  padding: 22px 16px;
  width: 220px;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
`

export default {
  LogBox: styled.div`
    flex-grow: 100;
    height: 100%;
    white-space: pre-wrap;
    font-family: monospace;
    background-color: #171717;
    color: #ccc;
    font-size: 15px;
    padding: 10px;
  `,
  EmptyLogBox: styled.div`
    width: 100%
    height: 100%;
    white-space: pre-wrap;
    font-family: monospace;
    background-color: #171717;
    color: #ccc;
    font-size: 15px;
    padding: 10px;
  `,
  PageButtonInactive: styled.button`
    ${button}
    background-color: inherit;
    &:hover {
      background-color: #ddd;
    }
    &:focus {
      outline: none;
      outline-offset: none;
    }
  `,
  PageButtonActive: styled.button`
    background-color: #ccc;
    ${button}
    &:focus {
      outline: none;
      outline-offset: none;
    }
  `,
  PageBody: styled.div`
    width: 100%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  `,
  PageBodyCentered: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  PageHeading: styled(H2)`
    margin-bottom: 0px;
  `,
  PageContainer: styled.div`
    width: 100%;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
      0 1px 1px rgba(16, 22, 26, 0.2);
    ${flex.flexCenteredColumn}
    border-radius: 5px;
    margin: 10px;
    height: 85%;
    overflow-y: hidden;
    overflow-x: auto;
  `,
  Tab: styled.div`
    background-color: #f1f1f1;
    border-right: 1px solid #ccc;
  `,
  LoadingSpinner: styled(Spinner).attrs(() => ({
    className: 'LoadingSpinner',
    size: 80
  }))`
    width: 100%;
  `,
  ErrorContainer: styled.div`
    ${flex.flexCenteredRow}
    justify-content: center;
    align-items: center;
    height: 100%;
  `,
  PageCategoryHeading: styled.div`
    height: 80px;
  `,
  PageBodyContainer: styled.div`
    width: 100%;
    height: 100%
    ${flex.flexCenteredRow}
    min-height: 512px;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 0.1px solid #ccc;
    align-items: stretch;
  `,
  ActionMenuIcon: styled(Icon).attrs(() => ({
    icon: 'more',
    iconSize: 20
  }))``,
  ModelResults: {
    AccuracyHeadingIcon: styled(Icon).attrs(() => ({
      icon: 'scatter-plot',
      iconSize: 20
    }))`
      margin-right: 8px;
    `,
    ModelImportanceContainer: styled.div`
      ${flex.flexCenteredRow}
      height: 100%;
      align-items: start;
    `,
    ModelRatesContainer: styled.div`
      ${flex.flexCenteredRow}
      height: 100%;
      width: 100%;
      align-items: center;
    `,
    ConfusionMatrixContainer: styled.div`
      ${flex.flexCenteredColumn}
      margin-left: auto;
      margin-right: auto;
    `,
    TrueFalseHeading: styled(H4)`
      margin: 5px;
      white-space: nowrap;
    `,
    TrueFalseContainer: styled.div`
      ${flex.flexCenteredColumn}
      align-items: start;
    `,
    GainsCurveImage: styled.img`
      margin-left: auto;
      margin-right: auto;
    `,
    ShapContainer: styled.div`
      ${flex.flexCenteredColumn}
      height: 100%;
      align-items: start;
    `,
    TreeContainer: styled.div`
      ${flex.flexCenteredColumn}
      height: 100%;
      align-items: start;
    `
  },
  PredictionResults: {
    ModelButton: styled(Button)`
      color: white;
      && {
        min-width: auto;
        min-height: unset;
        padding: 2px 15px;
        font-size: unset;
        margin: 0px;
      }
      background-color: #26de81;
      :hover {
        background-color: #1bb669;
      }
    `
  }
}
