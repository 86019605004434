import React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'common/redux/sitka/sitka_octain'
import { H4, H2 } from 'ui/styledComponents/constants'
import results_styles from 'ui/styledComponents/results_styles'

interface Props {
  readonly sitkaState: AppState
}

const Component: React.FC<Props> = ({
  sitkaState: {
    models: {
      modelResults: {
        files: { 'shap_lines.png': shapLines, 'shap_image.png': shapImage },
        state
      },
      modelResultsStatus
    }
  }
}) => {
  const { ErrorContainer } = results_styles
  const { ShapContainer } = results_styles.ModelResults
  if (state !== 'DONE') {
    return (
      <ErrorContainer>
        <H2>Error Loading Artifact</H2>
      </ErrorContainer>
    )
  }

  return (
    <ShapContainer>
      <H2>Sample Individual Plot</H2>
      <p>Red indicates upward effect, blue indicates downward effect.</p>
      <img src={shapLines} alt={'Shap Lines'} />
      <H2>Summary Plot By Feature</H2>
      <img src={shapImage} alt={'Shap Image'} />
    </ShapContainer>
  )
}

export const ModelResultsShap = connect((state: AppState) => {
  return {
    sitkaState: state
  }
})(Component)
