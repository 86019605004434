import { BaseRepository } from 'common/redux/sitka/basemap'
import { FetchStatus } from 'enums'

export interface OctainModelFiles {
  'csv_post.csv': string
  'cv1.csv': string
  'cv2.csv': string
  'cv3.csv': string
  'cv4.csv': string
  'force_plot.html': string
  'lift_image.png': string
  'log.txt': string
  'sk_log.txt': string
  model: string
  ohe: string
  'shap_image.png': string
  'shap_lines.png': string
  'shap_plots.csv': string
  'tree.png': string
  'top20_image.png': string
}

export interface OctainModelFileData {
  cv1: Array<Array<string>>
  cv2: Array<Array<string>>
  cv3: Array<Array<string>>
  log: string
  cv1Status: FetchStatus
  cv2Status: FetchStatus
  cv3Status: FetchStatus
  logStatus: FetchStatus
}

export interface OctainModelResults extends OctainModel {
  readonly fileData: OctainModelFileData
}

export interface OctainModel {
  readonly id: string
  readonly datasetId: string
  readonly datasetName: string
  readonly state: string
  readonly accuracy: string
  readonly archived: boolean
  readonly createdAt: string
  readonly updatedAt: string
  readonly error: string
  readonly name: string
  readonly idVariable: string
  readonly responseVariable: string
  readonly predictionVariables: string
  readonly trainingTime: string
  readonly method: string
  readonly top20: Array<Array<string>>
  readonly tbl: Array<Array<string>>
  readonly tp: string
  readonly tn: string
  readonly fp: string
  readonly fn: string
  readonly precision: string
  readonly recall: string
  readonly partialDependence: string
  readonly files: OctainModelFiles
}

export interface OctainModels extends BaseRepository<OctainModel> {}

export interface OctainModelState {
  readonly models: OctainModels
  readonly modelResults: OctainModelResults
  readonly status: FetchStatus
  readonly modelResultsStatus: FetchStatus
}

export const defaultOctainModelFiles: OctainModelFiles = {
  'csv_post.csv': '',
  'cv1.csv': '',
  'cv2.csv': '',
  'cv3.csv': '',
  'cv4.csv': '',
  'force_plot.html': '',
  'lift_image.png': '',
  'log.txt': '',
  'sk_log.txt': '',
  model: '',
  ohe: '',
  'shap_image.png': '',
  'shap_lines.png': '',
  'shap_plots.csv': '',
  'tree.png': '',
  'top20_image.png': ''
}

export const defaultOctainModelFileData = (
  state: FetchStatus
): OctainModelFileData => ({
  cv1: [[]],
  cv2: [[]],
  cv3: [[]],
  log: '',
  cv1Status: state,
  cv2Status: state,
  cv3Status: state,
  logStatus: state
})

export const defaultOctainModelResults: OctainModelResults = {
  id: '',
  datasetId: '',
  datasetName: '',
  state: '',
  accuracy: '',
  createdAt: '',
  updatedAt: '',
  error: '',
  name: '',
  idVariable: '',
  responseVariable: '',
  predictionVariables: '',
  trainingTime: '',
  method: '',
  top20: [[]],
  tbl: [[]],
  tp: '',
  tn: '',
  fp: '',
  fn: '',
  precision: '',
  recall: '',
  partialDependence: '',
  archived: false,
  files: defaultOctainModelFiles,
  fileData: defaultOctainModelFileData(FetchStatus.none)
}
