import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { DatasetModule } from 'common/redux/sitka/octain_dataset/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import { H3, H4, PageHeadingContainer, H2 } from 'ui/styledComponents/constants'
import { DatasetList } from 'ui/components/models/model_create/dataset_list'
import { useHistory } from 'react-router'
import create_dataset_styles from 'ui/components/predictions/prediction_create/create_dataset_styles'
import { DatasetTypes } from 'common/redux/sitka/octain_dataset/core'
import {
  PredictionWizard,
  predictionWizardStage
} from 'common/redux/sitka/current_session/core'
import predictions_styles from 'ui/components/predictions/predictions_styles'

interface CreateDatasetProps {
  readonly currentSession: CurrentSessionModule
  readonly datasetModule: DatasetModule
  readonly sitkaState: AppState
  readonly wizard: PredictionWizard
  readonly setWizard: React.Dispatch<React.SetStateAction<PredictionWizard>>
}

const Component: React.FC<CreateDatasetProps> = ({
  currentSession,
  datasetModule,
  wizard,
  setWizard,
  sitkaState: {
    datasets: { datasets, status, uploadStatus }
  }
}) => {
  const [drag, setDrag] = useState<boolean>(false)
  const history = useHistory()

  const {
    uploadHeading,
    dragHeadingOver,
    dragHeadingNotOver,
    uploadText,
    selectDataset
  } = strings.modelChooseData

  const {
    FileDropContainer,
    FileDrop,
    UploadIcon,
    DatasetContainer
  } = create_dataset_styles

  const { PredictionsHeadingIcon } = predictions_styles

  const onChooseDataset = (id: string) => {
    setWizard({
      ...wizard,
      datasetId: id,
      stage: predictionWizardStage.PREVIEW
    })
    history.push('/predictions/new/preview')
  }

  const DragHeading = drag ? (
    <H3>{dragHeadingOver}</H3>
  ) : (
    <H3>{dragHeadingNotOver}</H3>
  )

  const uploadFile = (file: Array<File>) => {
    datasetModule.handleCreateDataset(file, DatasetTypes.PREDICTION)
  }

  return (
    <>
      <PageHeadingContainer>
        <H2>
          <PredictionsHeadingIcon />
          {'Create Prediction - Select Dataset'}
        </H2>
      </PageHeadingContainer>
      <DatasetContainer>
        <H4>Upload new prediction dataset</H4>
        <Dropzone
          onDrop={uploadFile}
          onDragEnter={() => setDrag(true)}
          onDragLeave={() => setDrag(false)}
          onDropAccepted={() => setDrag(false)}
          onDropRejected={() => setDrag(false)}
        >
          {({ getRootProps, getInputProps }) => (
            <FileDropContainer>
              <FileDrop {...getRootProps()} drag={drag}>
                <input {...getInputProps()} />
                <UploadIcon />
                {DragHeading}
                <H4>{uploadText}</H4>
              </FileDrop>
            </FileDropContainer>
          )}
        </Dropzone>

        <H4>{selectDataset}</H4>

        <DatasetList
          uploadStatus={uploadStatus}
          status={status}
          datasets={datasets}
          onSelectItem={onChooseDataset}
        />
      </DatasetContainer>
    </>
  )
}

export const CreateDataset = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    datasetModule: modules.datasets,
    sitkaState: state
  }
})(Component)
