import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Icon, IIconProps } from '@blueprintjs/core'

export const padding = {
  p1: css`
    padding: 4px;
  `,
  p2: css`
    padding: 10px;
  `,
  p3: css`
    padding: 20px;
  `,
  p4: css`
    padding: 40px;
  `
}

export const margin = {
  m1: css`
    margin: 4px;
  `,
  m2: css`
    margin: 10px;
  `,
  m3: css`
    margin: 20px;
  `,
  m4: css`
    margin: 40px;
  `
}

export const screenHeight = css`
  height: calc(100vh - 80px);
`

export const ContentContainer = styled.div`
  overflow-y: overlay;
  ${screenHeight}
  margin-top: 80px;
`

export const flex = {
  flexCenteredColumn: css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  flexCenteredRow: css`
    display: flex;
    align-items: center;
    flex-direction: row;
  `
}

export const color = {
  PrimaryA: css`
    color: white;
    background-color: #1680ff;
  `,
  PrimaryADark: css`
    color: white;
    background-color: #0065e0;
  `,
  PrimaryB: css`
    color: white;
    background-color: #26de81;
  `,
  PrimaryBDark: css`
    color: white;
    background-color: #1bb669;
  `,
  SecondaryA: css`
    color: #26de81;
    background-color: #132684;
  `,
  SecondaryB: css`
    color: #c6cbd1;
  `,
  FGPrimaryA: css`
    color: #1680ff;
  `,
  FGPrimaryB: css`
    color: #26de81;
  `,
  BGPrimaryA: css`
    background-color: #1680ff;
  `,
  BGPrimaryB: css`
    background-color: #26de81;
  `,
  FGSecondaryA: css`
    color: #132684;
  `,
  FGSecondaryB: css`
    color: #c6cbd1;
  `,
  BGSecondaryA: css`
    background-color: #132684;
  `,
  BGSecondaryB: css`
    background-color: #132684;
  `
}

export const button = {
  small: css`
    min-width: 140px;
    min-height: 46px;
    padding: 5px 15px;
    font-size: 16px;

    border: none;
    background-color: #6867f2;
    color: white;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
    vertical-align: middle;
    text-align: left;
  `
}

export const H1 = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  ${color.FGSecondaryA}
`

export const H2 = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  ${color.FGSecondaryA}
`

export const H3 = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  ${color.FGSecondaryA}
`

export const H4 = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  ${color.FGSecondaryA}
`

export const cn = (className: string) => ({ className: className })

export const Container = styled.div`
  ${flex.flexCenteredColumn}
  ${padding.p2}
  color: #010149;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: #010149;
`

export const SLink = styled(Link)`
  color: black;
  &:hover {
    text-decoration: none;
  }
`

export const StyledTable = styled.table.attrs(
  cn(
    'bp3-html-table bp3-html-table-bordered bp3-html-table-condensed bp3-html-table-striped bp3-interactive'
  )
)`
  border-bottom: 0.1px solid #ccc;
  margin-bottom: -1px;
  width: 100%;
`

export const PageHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 82px;
`

interface RefreshProps {
  readonly processing: number
}

export const RefreshButton = styled(Icon).attrs(() => ({
  icon: 'refresh',
  iconSize: 20
}))<RefreshProps & IIconProps>`
  animation: spin 2s linear infinite;
  animation-play-state: ${props => (props.processing ? 'running' : 'paused')};
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`
