import styled from 'styled-components'
import { Icon, IIconProps } from '@blueprintjs/core'
import { flex, padding, margin } from 'ui/styledComponents/constants'

interface FileDropProps {
  readonly drag: boolean
}

export default {
  FileDropContainer: styled.section`
    ${margin.m1}
    height: 130px;
  `,
  FileDrop: styled.div<FileDropProps>`
    ${flex.flexCenteredColumn}
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
    height: 130px;
    border: 2px dashed grey;
    ${padding.p2}    
    background-color: ${props => (props.drag ? '#accafd' : 'white')};
    h3 {
      ${margin.m1}
    }
    h4 {
      ${margin.m1}
      color: #9291f6;
    }
  `,
  UploadIcon: styled(Icon).attrs(
    (): IIconProps => ({
      icon: 'cloud-upload',
      iconSize: 20
    })
  )``,
  DatasetContainer: styled.div`
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  `
}
