import React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'common/redux/sitka/sitka_octain'
import { ModelResultsTable } from 'ui/components/models/model_results/model_results_table'
import { H2 } from 'ui/styledComponents/constants'
import { FetchStatus } from 'enums'
import results_styles from 'ui/styledComponents/results_styles'
import { LoadingWrapper } from 'ui/components/loading_wrapper'

interface Props {
  readonly sitkaState: AppState
}

const Component: React.FC<Props> = ({
  sitkaState: {
    models: {
      modelResults: {
        top20,
        files: { 'top20_image.png': top20Image }
      },
      modelResultsStatus: status
    }
  }
}) => {
  const { ErrorContainer, LoadingSpinner } = results_styles
  const { ModelImportanceContainer } = results_styles.ModelResults

  const done = (
    <ModelImportanceContainer>
      <ModelResultsTable table={top20} status={status} />
      <img src={top20Image} alt={'Top20'} />
    </ModelImportanceContainer>
  )
  const error = (
    <ErrorContainer>
      <H2>Error Loading Artifact</H2>
    </ErrorContainer>
  )

  const loading = <LoadingSpinner />

  const none = loading

  return <LoadingWrapper {...{ done, loading, error, none, status }} />
}

export const ModelResultsImportance = connect((state: AppState) => {
  return {
    sitkaState: state
  }
})(Component)
