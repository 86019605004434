const parse = require('csv-parse/lib/sync')

export const parseCSV = (data: any) => {
  return parse(data, {
    columns: false,
    skip_empty_lines: true
  })
}

export const parseDataset = (data: Array<any>) => {
  return data.map(item => {
    const columnNames = parse(item.columnNames, {
      columns: false,
      skip_empty_lines: true
    })[0]

    let preview: Array<Array<string>> = [[]]
    try {
      preview = parse(item.preview, {
        columns: false,
        skip_empty_lines: true
      })
    } catch (error) {
      preview = [['Data too large or could not be parsed']]
    }
    return { ...item, columnNames, preview }
  })
}
