import { SitkaModule } from 'olio-sitka'
import { put, select } from 'redux-saga/effects'
import { AppModules, AppState } from 'common/redux/sitka/sitka_octain'
import {
  CurrentSessionState,
  authState,
  CurrentSession,
  ModelWizard,
  PredictionWizard
} from 'common/redux/sitka/current_session/core'
import {
  ResponseModel,
  login,
  checkAuth,
  createModel,
  createPrediction,
  createUser,
  patchUser
} from 'common/client'
import { NewUserWizard } from 'common/redux/sitka/admin/core'
export class CurrentSessionModule extends SitkaModule<
  CurrentSessionState,
  AppModules
> {
  public moduleName: string = 'currentSession'
  public defaultSession: CurrentSession = {
    name: '',
    picture: '',
    email: '',
    permissions: ''
  }

  public defaultState: CurrentSessionState = {
    redirect: '',
    session: this.defaultSession,
    errorCode: '',
    auth: authState.CHECKTOKEN
  }

  public *handleModelCreateTrainModel(modelWizard: ModelWizard) {
    const sessionState: CurrentSessionState = yield select(
      CurrentSessionModule.selectSessionState
    )
    yield createModel(modelWizard)

    yield put(
      this.setState({
        ...sessionState,
        redirect: '/models'
      })
    )
  }

  public *handleCreateUser(user: NewUserWizard) {
    const sessionState: CurrentSessionState = yield select(
      CurrentSessionModule.selectSessionState
    )

    yield createUser(user)

    yield put(
      this.setState({
        ...sessionState,
        redirect: '/admin'
      })
    )
  }

  public *handlePatchUser(id: string, user: NewUserWizard) {
    const sessionState: CurrentSessionState = yield select(
      CurrentSessionModule.selectSessionState
    )

    yield patchUser(id, { email: user.email, permissions: user.permissions })

    yield put(
      this.setState({
        ...sessionState,
        redirect: '/admin'
      })
    )
  }

  //Auth
  public *handleLogin(userData: CurrentSession, sessionToken: string) {
    const sessionState: CurrentSessionState = yield select(
      CurrentSessionModule.selectSessionState
    )
    yield put(
      this.setState({
        ...sessionState,
        auth: authState.PROCESSING
      })
    )
    const resp: ResponseModel = yield login(sessionToken)
    if (resp.status !== 200) {
      localStorage.removeItem('octainSessionToken')
      yield put(
        this.setState({ ...this.defaultState, errorCode: `${resp.status}` })
      )
      return
    }
    yield put(
      this.setState({
        ...sessionState,
        session: resp.data,
        auth: authState.AUTHENTICATED
      })
    )
  }

  public *handleLogout(errorText: string) {
    localStorage.removeItem('octainSessionToken')
    yield put(
      this.setState({
        ...this.defaultState,
        errorCode: errorText
      })
    )
  }

  public *handleCheckToken() {
    const sessionState: CurrentSessionState = yield select(
      CurrentSessionModule.selectSessionState
    )
    yield put(
      this.setState({
        ...sessionState,
        auth: authState.PROCESSING
      })
    )
    if (!localStorage.getItem('octainSessionToken')) {
      yield put(
        this.setState({
          ...sessionState,
          auth: authState.UNAUTHENTICATED,
          session: this.defaultSession
        })
      )
      return
    }
    const resp: ResponseModel = yield checkAuth()
    if (resp.status !== 200) {
      localStorage.removeItem('octainSessionToken')
      yield put(
        this.setState({
          ...sessionState,
          auth: authState.UNAUTHENTICATED,
          session: this.defaultSession
        })
      )
      return
    }
    yield put(
      this.setState({
        ...sessionState,
        auth: authState.AUTHENTICATED,
        session: resp.data
      })
    )
  }

  public *authCheck(resp: ResponseModel) {
    if (resp.status === 422 || resp.status === 401) {
      yield this.handleLogout(`${resp.status}`)
    }
    return resp
  }

  //Redirect
  public *handleRedirect(path: string) {
    const sessionState: CurrentSessionState = yield select(
      CurrentSessionModule.selectSessionState
    )
    yield put(
      this.setState({
        ...sessionState,
        redirect: path
      })
    )
  }

  public *handleClearRedirect() {
    const sessionState: CurrentSessionState = yield select(
      CurrentSessionModule.selectSessionState
    )
    yield put(
      this.setState({
        ...sessionState,
        redirect: ''
      })
    )
  }

  //Predictions
  public *handleCreatePrediction(predictionWizard: PredictionWizard) {
    const sessionState: CurrentSessionState = yield select(
      CurrentSessionModule.selectSessionState
    )
    yield createPrediction(predictionWizard)

    yield put(
      this.setState({
        ...sessionState,
        redirect: '/predictions'
      })
    )
  }

  public static selectSessionState(state: AppState): CurrentSessionState {
    return state.currentSession
  }
}
