import { SitkaModule } from 'olio-sitka'
import { put, select } from 'redux-saga/effects'
import {
  OctainModelState,
  OctainModelResults,
  defaultOctainModelResults,
  defaultOctainModelFileData
} from 'common/redux/sitka/octain_model/core'
import { AppModules, AppState } from 'common/redux/sitka/sitka_octain'
import {
  defaultRepository,
  parseArrayToBaseRepo,
  removeFromBaseRepo
} from 'common/redux/sitka/basemap'
import {
  ResponseModel,
  getOctainModels,
  getOctainModel,
  get_file,
  patchModel
} from 'common/client'
import { parseCSV } from 'common/redux/sitka/octain_dataset/util'
import {
  getResponseStatus,
  getDataFromResponse,
  parseCSVinModels,
  parseCSVinModel
} from 'common/redux/sitka/octain_model/util'
import { FetchStatus } from 'enums'
import { patchModelsParams, getModelsParams } from 'common/request_shape'

export class ModelModule extends SitkaModule<OctainModelState, AppModules> {
  public moduleName: string = 'models'

  public defaultState: OctainModelState = {
    models: defaultRepository(),
    status: FetchStatus.none,
    modelResults: defaultOctainModelResults,
    modelResultsStatus: FetchStatus.none
  }

  public *handlePatchModel(id: string, params: patchModelsParams) {
    const response: ResponseModel = yield patchModel(id, params)
    if (response.status === 200) {
      const modelState: OctainModelState = yield select(
        ModelModule.selectModelState
      )
      if (params.archived !== undefined) {
        yield put(
          this.setState({
            ...modelState,
            models: removeFromBaseRepo(id, modelState.models),
            status: FetchStatus.done
          })
        )
      }
      if (params.archived !== undefined && modelState.modelResults.id) {
        yield put(
          this.setState({
            ...modelState,
            modelResults: {
              ...modelState.modelResults,
              archived: params.archived
            },
            status: FetchStatus.done
          })
        )
      }
    }
  }

  public *handleGetModels(params: getModelsParams) {
    const modelState: OctainModelState = yield select(
      ModelModule.selectModelState
    )

    yield put(
      this.setState({ ...this.defaultState, status: FetchStatus.loading })
    )

    const rawOctainModels: ResponseModel = yield this.modules.currentSession.authCheck(
      yield getOctainModels(params)
    )
    if (rawOctainModels.status !== 200) {
      yield put(
        this.setState({ ...this.defaultState, status: FetchStatus.error })
      )
      return
    }
    yield put(
      this.setState({
        ...modelState,
        models: parseArrayToBaseRepo(parseCSVinModels(rawOctainModels.data)),
        status: FetchStatus.done
      })
    )
  }

  public *handleGetModelResults(
    modelId: string,
    forceFetch: boolean = false
  ): {} {
    const loadingModelState: OctainModelState = yield select(
      ModelModule.selectModelState
    )

    // Check for model in state, if found copy old state to results
    if (loadingModelState.models.items[modelId] && !forceFetch) {
      const copyModel: OctainModelResults = {
        ...defaultOctainModelResults,
        ...loadingModelState.models.items[modelId],
        fileData: defaultOctainModelFileData(FetchStatus.loading)
      }
      yield put(
        this.setState({
          ...loadingModelState,
          modelResultsStatus: FetchStatus.done,
          modelResults: copyModel
        })
      )
    } else {
      yield put(
        this.setState({
          ...loadingModelState,
          modelResultsStatus: FetchStatus.loading,
          modelResults: {
            ...defaultOctainModelResults,
            fileData: defaultOctainModelFileData(FetchStatus.loading)
          }
        })
      )
    }

    const results: ResponseModel = yield this.modules.currentSession.authCheck(
      yield getOctainModel(modelId)
    )
    if (results.status !== 200) {
      const modelState: OctainModelState = yield select(
        ModelModule.selectModelState
      )
      yield put(
        this.setState({
          ...modelState,
          modelResultsStatus: FetchStatus.error,
          modelResults: {
            ...modelState.modelResults,
            fileData: defaultOctainModelFileData(FetchStatus.error)
          }
        })
      )
      return
    }
    const model = parseCSVinModel(results.data[0])

    const modelState = yield select(ModelModule.selectModelState)

    yield put(
      this.setState({
        ...modelState,
        modelResultsStatus: FetchStatus.done,
        modelResults: { ...modelState.modelResults, ...model }
      })
    )
    if (model.state === 'DONE') {
      const cv1: ResponseModel = yield get_file(model.files['cv1.csv'])
      yield this.updateFileData('cv1', 'cv1Status', cv1)

      const cv2: ResponseModel = yield get_file(model.files['cv2.csv'])
      yield this.updateFileData('cv2', 'cv2Status', cv2)

      const cv3: ResponseModel = yield get_file(model.files['cv3.csv'])
      yield this.updateFileData('cv3', 'cv3Status', cv3)
    }

    if (
      model.state === 'DONE' ||
      model.state === 'PROCESSING' ||
      model.state === 'ERROR'
    ) {
      const log: ResponseModel = yield get_file(model.files['log.txt'])
      yield this.updateFileData('log', 'logStatus', log, false)
    } else {
      yield this.updateFileData(
        'log',
        'logStatus',
        { status: 200, statusText: '', data: 'no log entries' },
        false
      )
    }
  }

  public *updateFileData(
    dataName: string,
    dataStatus: string,
    response: ResponseModel,
    CSV: boolean = true
  ) {
    const modelState = yield select(ModelModule.selectModelState)
    yield put(
      this.setState({
        ...modelState,
        modelResults: {
          ...modelState.modelResults,
          fileData: {
            ...modelState.modelResults.fileData,
            [dataName]: CSV
              ? parseCSV(getDataFromResponse(response))
              : getDataFromResponse(response),
            [dataStatus]: getResponseStatus(response)
          }
        }
      })
    )
  }

  public static selectModelState(state: AppState): OctainModelState {
    return state.models
  }
}
