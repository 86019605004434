import React from 'react'
import GoogleLogin from 'react-google-login'
import { connect } from 'react-redux'
import { authState } from 'common/redux/sitka/current_session/core'
import { strings } from 'ui/components/strings'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { loginStyles } from 'ui/login/login_styles'
import LogoImage from 'ui/images/wide_-_color_on_transparent__rgb-02.png'

const G_APP_ID: string = process.env.REACT_APP_G_APP_ID || ''

const Eula = '<center>By logging in you agree to abide by the terms and conditions set forth in the <a href="https://www.kinandcarta.com/en-us/services/data-and-ai/octain/eula/">open source licensing agreement</a>.</center>'

interface LoginProps {
  readonly currentSession: CurrentSessionModule
  readonly sitkaState: AppState
}

const Component: React.FC<LoginProps> = ({
  currentSession,
  sitkaState: {
    currentSession: { auth, errorCode }
  }
}) => {
  const responseGoogle = (resp: any) => {
    currentSession.handleLogin(
      {
        email: resp.profileObj.email,
        picture: resp.profileObj.imageUrl,
        name: resp.profileObj.name
      },
      resp.tokenObj.id_token
    )
  }

  const {
    Container,
    LoadingSpinner,
    Logo,
    LogoContainer,
    LoginButton
  } = loginStyles

  const LoginButtonComponent = (props: {
    onClick: () => void
    disabled?: boolean | undefined
  }) => <LoginButton {...props}>Login with Google</LoginButton>

  return (
      <>
      <div dangerouslySetInnerHTML={{__html: Eula}} />
      <Container>
        <LogoContainer>
          <Logo src={LogoImage} alt={'Octain'} />
        </LogoContainer>
        {auth === authState.PROCESSING ? (
          <LoadingSpinner />
        ) : (
          <GoogleLogin
            clientId={G_APP_ID}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            render={LoginButtonComponent}
          />

        )}
      </Container>
      </>
  )
}

export const Login = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    sitkaState: state
  }
})(Component)

interface ErrorMessageProps {
  errorCode: any
}
