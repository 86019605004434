import React, { useState } from 'react'
import { connect } from 'react-redux'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { PageButtons } from 'ui/components/page-buttons/page-buttons'
import {
  AdminUsers,
  NewUserWizard,
  AdminUser
} from 'common/redux/sitka/admin/core'
import { SmallToggleButton } from 'ui/styledComponents/buttons'
import admin_user_list_styles from 'ui/components/admin/admin_user_list_styles'
import { InputGroup, Radio } from '@blueprintjs/core'
import { ButtonSecondary } from 'ui/styledComponents/buttons'
import { AdminModule } from 'common/redux/sitka/admin/module'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'

interface Props {
  readonly currentSession: CurrentSessionModule
  readonly adminModule: AdminModule
  readonly users: AdminUsers
  readonly sitkaState: AppState
}

const Component: React.FC<Props> = ({ users, adminModule, currentSession }) => {
  const [page, setPage] = useState(0)
  const [edit, setEdit] = useState<string>('')
  const [editUser, setEditUser] = useState<NewUserWizard>({
    email: '',
    permissions: 'USER'
  })
  const [newUser, setNewUser] = useState<NewUserWizard>({
    email: '',
    permissions: 'USER'
  })

  const resultsPerPage = 8
  const displaySort = users
    ? users.sort.slice(page * resultsPerPage, (page + 1) * resultsPerPage)
    : []
  const totalPages = Math.ceil(users.sort.length / resultsPerPage)
  const {
    Border,
    ModelTable,
    ActionIcons: { Edit }
  } = admin_user_list_styles

  const toggleEdit = (id: string) => {
    if (id === edit || id === '') {
      setEdit('')
      setEditUser({ email: '', permissions: '' })
    } else {
      setEdit(id)
      setEditUser({
        email: users.items[id].email,
        permissions: users.items[id].permissions
      })
    }
  }

  const onEditPermissionChange = (event: any) => {
    setEditUser({ ...editUser, permissions: event.target.value })
  }

  const onEditNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditUser({
      ...editUser,
      email: event.target.value
    })
  }

  const onSaveEditUser = (id: string) => {
    if (editUser.email !== '') {
      currentSession.handlePatchUser(id, editUser)
    }
  }

  const ModelList = displaySort.map(id => {
    const { email, permissions, lastUpdatedBy, lastLogin } = users.items[id]
    const editing = edit === id

    return editing ? (
      <EditUserField key={id}
        {...{
          editUser,
          onSaveEditUser,
          onEditNameChange,
          onEditPermissionChange,
          oldUser: users.items[id],
          toggleEdit
        }}
      />
    ) : (
      <tr key={id}>
        <td>{email}</td>
        <td>{permissions}</td>
        <td>{lastLogin}</td>
        <td>{lastUpdatedBy}</td>
        <td>
          <SmallToggleButton active={editing} onClick={() => toggleEdit(id)}>
            <Edit />
          </SmallToggleButton>
        </td>
      </tr>
    )
  })

  const onPermissionChange = (event: any) => {
    setNewUser({ ...newUser, permissions: event.target.value })
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({
      ...newUser,
      email: event.target.value
    })
  }

  const onCreateUser = () => {
    if (newUser.email !== '') {
      currentSession.handleCreateUser(newUser)
    }
  }

  return (
    <>
      <Border>
        <ModelTable>
          <thead>
            <tr>
              <th>Email</th>
              <th>Permissions</th>
              <th>Last Activity On</th>
              <th>Last Modified By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <NewUserField
              {...{ newUser, onCreateUser, onNameChange, onPermissionChange }}
            />
            {ModelList}
          </tbody>
        </ModelTable>
      </Border>
      <PageButtons {...{ page, setPage, totalPages }} />
    </>
  )
}

export const UserList = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    adminModule: modules.admin,
    sitkaState: state
  }
})(Component)

interface NewUserProps {
  readonly onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  readonly onPermissionChange: (event: any) => void
  readonly onCreateUser: () => void
  readonly newUser: NewUserWizard
}

const NewUserField: React.FC<NewUserProps> = ({
  newUser,
  onNameChange,
  onPermissionChange,
  onCreateUser
}) => {
  const { RadioGroup } = admin_user_list_styles
  return (
    <tr>
      <td>
        <InputGroup
          onChange={onNameChange}
          type={'email'}
          large
          value={newUser.email}
          placeholder={'New User Email'}
        />
      </td>
      <td>
        <RadioGroup
          onChange={onPermissionChange}
          selectedValue={newUser.permissions}
        >
          <Radio label={'None'} value={'NONE'} />
          <Radio label={'User'} value={'USER'} />
          <Radio label={'Admin'} value={'ADMIN'} />
        </RadioGroup>
      </td>
      <td></td>
      <td></td>
      <td>
        <ButtonSecondary onClick={onCreateUser}>Save</ButtonSecondary>
      </td>
    </tr>
  )
}

interface EditUserProps {
  readonly oldUser: AdminUser
  readonly onEditNameChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  readonly onEditPermissionChange: (event: any) => void
  readonly onSaveEditUser: (id: string) => void
  readonly editUser: NewUserWizard
  readonly toggleEdit: (id: string) => void
}

const EditUserField: React.FC<EditUserProps> = ({
  oldUser,
  editUser,
  onEditNameChange,
  onEditPermissionChange,
  onSaveEditUser,
  toggleEdit
}) => {
  const {
    RadioGroup,
    SelectedTableRow,
    ActionIcons: { Confirm, Cancel }
  } = admin_user_list_styles
  return (
    <SelectedTableRow>
      <td>
        <InputGroup
          onChange={onEditNameChange}
          type={'email'}
          large
          value={editUser.email}
          placeholder={oldUser.email}
        />
      </td>
      <td>
        <RadioGroup
          onChange={onEditPermissionChange}
          selectedValue={editUser.permissions}
        >
          <Radio label={'None'} value={'NONE'} />
          <Radio label={'User'} value={'USER'} />
          <Radio label={'Admin'} value={'ADMIN'} />
        </RadioGroup>
      </td>
      <td>{oldUser.lastLogin}</td>
      <td>{oldUser.lastUpdatedBy}</td>
      <td>
        <SmallToggleButton active={true} onClick={() => toggleEdit(oldUser.id)}>
          <Cancel />
        </SmallToggleButton>

        <SmallToggleButton
          active={true}
          onClick={() => onSaveEditUser(oldUser.id)}
        >
          <Confirm />
        </SmallToggleButton>
      </td>
    </SelectedTableRow>
  )
}
