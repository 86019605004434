import React from 'react'
import { OctainDataset } from 'common/redux/sitka/octain_dataset/core'
import { strings } from 'ui/components/strings'
import preview_list_styles from 'ui/components/models/model_create/preview_list_styles'

interface previewListProps {
  dataset: OctainDataset
  predictorColumns?: ReadonlyArray<number>
  idColumn?: number
  responseColumn?: number
  onSelectItem?: (id: string) => void
}

const PreviewList: React.FC<previewListProps> = ({
  dataset,
  onSelectItem = (id: string) => {},
  idColumn = -1,
  responseColumn = -1,
  predictorColumns = []
}) => {
  const {
    common: { networkError },
    dataList: { emptyDatasets, columnName, columnDescription, columnTags }
  } = strings
  const {
    ModelTable,
    DatalistContainer,
    SelectedCell,
    IDCell,
    ResponseCell,
    IndexCell
  } = preview_list_styles
  const columns = dataset.preview[0]
  const rows = dataset.preview.slice(1)
  interface TableRowProps {
    row: ReadonlyArray<string>
    onClick: (id: string) => void
  }

  const TableRow: React.FC<TableRowProps> = ({ row, onClick }) => {
    return (
      <tr>
        {row.map((item, i) => {
          const text = clipCell(item)
          return i === 0 ? (
            <IndexCell key={'tableRow' + i} onClick={() => onClick(`${i}`)}>
              {text}
            </IndexCell>
          ) : idColumn === i ? (
            <IDCell key={'tableRow' + i} onClick={() => onClick(`${i}`)}>
              {text}
            </IDCell>
          ) : responseColumn === i ? (
            <ResponseCell key={'tableRow' + i} onClick={() => onClick(`${i}`)}>
              {text}
            </ResponseCell>
          ) : predictorColumns.includes(i) ? (
            <SelectedCell key={'tableRow' + i} onClick={() => onClick(`${i}`)}>
              {text}
            </SelectedCell>
          ) : (
            <td key={'tableRow' + i} onClick={() => onClick(`${i}`)}>
              {text}
            </td>
          )
        })}
      </tr>
    )
  }

  return (
    <DatalistContainer>
      <ModelTable>
        <thead>
          <tr>
            {columns.map(column => (
              <th key={'previewList' + column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <TableRow key={row[0]} row={row} onClick={onSelectItem} />
          ))}
        </tbody>
      </ModelTable>
    </DatalistContainer>
  )
}

const clipCell = (item: string) => {
  if (item.length > 40) {
    return item.slice(0, 37) + ' ...'
  }
  return item
}

export { PreviewList }
