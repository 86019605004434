import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CurrentSessionModule } from 'common/redux/sitka/current_session/module'
import { DatasetModule } from 'common/redux/sitka/octain_dataset/module'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { strings } from 'ui/components/strings'
import { CreateDataset } from './create_datasets'
import prediction_create_styles from './prediction_create_styles'
import { useHistory, Switch, Route, Redirect } from 'react-router'
import { Container, H2 } from 'ui/styledComponents/constants'
import {
  PredictionWizard,
  predictionWizardDefault,
  predictionWizardStage
} from 'common/redux/sitka/current_session/core'
import { PredictionModel } from 'ui/components/predictions/prediction_create/prediction_model'
import { ModelModule } from 'common/redux/sitka/octain_model/module'
import { PreviewList } from 'ui/components/predictions/prediction_create/preview_list'
import { PredictionPreview } from 'ui/components/predictions/prediction_create/prediction_preview'

interface PredictionsChooseProps {
  readonly currentSession: CurrentSessionModule
  readonly datasetModule: DatasetModule
  readonly modelModule: ModelModule
  readonly sitkaState: AppState
}

const Component: React.FC<PredictionsChooseProps> = ({
  datasetModule,
  modelModule,
  currentSession,
  sitkaState: {
    datasets: { datasets, status }
  }
}) => {
  const [wizard, setWizard] = useState<PredictionWizard>(
    predictionWizardDefault
  )
  useEffect(() => {
    datasetModule.handleGetDatasets({})
    modelModule.handleGetModels({})
  }, [])

  const st = strings.modelChooseData
  const { CreatePredictionContainer, LoadingSpinner } = prediction_create_styles

  const chooseDataset = wizard.stage === predictionWizardStage.CHOOSE_DATASET
  const preview = wizard.stage === predictionWizardStage.PREVIEW
  const processing = wizard.stage === predictionWizardStage.PROCESSING

  return (
    <Container>
      <CreatePredictionContainer>
        <Switch>
          <Route path={'/predictions/new/model'}>
            <PredictionModel {...{ setWizard, wizard }} />
          </Route>

          <Route path={'/predictions/new/dataset'}>
            {chooseDataset || preview ? (
              <CreateDataset {...{ setWizard, wizard }} />
            ) : (
              <Redirect to={'/predictions/new/model'} />
            )}
          </Route>

          <Route path={'/predictions/new/preview'}>
            {preview ? (
              <PredictionPreview {...{ setWizard, wizard }} />
            ) : (
              <Redirect to={'/predictions/new/model'} />
            )}
          </Route>

          <Route path={'/predictions/new/processing'}>
            {processing ? (
              <>
                <H2>{'Building Prediction'}</H2>
                <LoadingSpinner />
              </>
            ) : (
              <Redirect to={'/predictions/new/model'} />
            )}
          </Route>

          <Redirect to={'/predictions/new/model'} />
        </Switch>
      </CreatePredictionContainer>
    </Container>
  )
}

export const PredictionCreate = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    currentSession: modules.currentSession,
    datasetModule: modules.datasets,
    modelModule: modules.models,
    sitkaState: state
  }
})(Component)
