import React from 'react'
import { FetchStatus } from 'enums'

interface Props {
  readonly status: FetchStatus
  readonly error: JSX.Element
  readonly done: JSX.Element
  readonly none: JSX.Element
  readonly loading: JSX.Element
}

export const LoadingWrapper: React.FC<Props> = ({
  status,
  done,
  error,
  loading,
  none
}) => {
  switch (status) {
    case FetchStatus.error:
      return <>{error}</>
    case FetchStatus.done:
      return <>{done}</>
    case FetchStatus.loading:
      return <>{loading}</>
    default:
    case FetchStatus.none:
      return <>{none}</>
  }
}
