import React from 'react'
import { Menu } from '@blueprintjs/core'
import { AppState, AppModules, sitka } from 'common/redux/sitka/sitka_octain'
import { connect } from 'react-redux'
import model_list_styles from 'ui/components/models/model_list_styles'
import { ModelModule } from 'common/redux/sitka/octain_model/module'
import { OctainModel } from 'common/redux/sitka/octain_model/core'

interface Props {
  readonly modelModule: ModelModule
  readonly model: OctainModel
  readonly id: string
}

const Component: React.FC<Props> = ({ id, model, modelModule }) => {
  const {
    Archive,
    UnArchive,
    DownloadLog,
    DownloadModel,
    DownloadOhe
  } = model_list_styles.ActionMenu

  const { archived, state } = model
  const {
    'log.txt': downloadLog,
    model: downloadModel,
    ohe: downloadOhe
  } = model.files
  const done = state === 'DONE'

  const archiveItem = () => {
    modelModule.handlePatchModel(id, { archived: true })
  }
  const unArchiveItem = () => {
    modelModule.handlePatchModel(id, { archived: false })
  }

  return (
    <Menu>
      {archived ? (
        <UnArchive text={'Restore'} onClick={unArchiveItem} />
      ) : (
        <Archive text={'Archive'} onClick={archiveItem} />
      )}
      {done && (
        <>
          <DownloadLog text={'Download Log'} href={downloadLog} />
          <DownloadModel
            text={'Download Model'}
            href={downloadModel}
            download
          />
          <DownloadOhe text={'Download Ohe'} href={downloadOhe} download />
        </>
      )}
    </Menu>
  )
}

export const ModelActionMenu = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    modelModule: modules.models,
    sitkaState: state
  }
})(Component)
