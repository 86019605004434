import { SitkaModule } from 'olio-sitka'
import { put, select, take, call } from 'redux-saga/effects'
import {
  OctainDatasetState,
  DatasetTypes,
  UploadStatus
} from 'common/redux/sitka/octain_dataset/core'
import { AppModules, AppState } from 'common/redux/sitka/sitka_octain'
import {
  defaultRepository,
  parseArrayToBaseRepo,
  removeFromBaseRepo,
  parseArrayToExistingBaseRepo,
  updateBaseRepoElements
} from 'common/redux/sitka/basemap'
import {
  ResponseModel,
  getDatasets,
  createDatasetUploadChannel,
  patchDataset
} from 'common/client'
import { parseDataset } from 'common/redux/sitka/octain_dataset/util'
import { FetchStatus } from 'enums'
import uuid from 'uuid'
import { patchDatasetsParams, getDatasetsParams } from 'common/request_shape'

export class DatasetModule extends SitkaModule<OctainDatasetState, AppModules> {
  public moduleName: string = 'datasets'

  public defaultState: OctainDatasetState = {
    datasets: defaultRepository(),
    status: FetchStatus.none,
    uploadStatus: defaultRepository()
  }

  public *handlePatchDataset(id: string, params: patchDatasetsParams) {
    const response: ResponseModel = yield patchDataset(id, params)
    if (response.status === 200) {
      const datasetState: OctainDatasetState = yield select(
        DatasetModule.selectDatasetState
      )
      if (params.archived !== undefined) {
        yield put(
          this.setState({
            ...datasetState,
            datasets: removeFromBaseRepo(id, datasetState.datasets),
            status: FetchStatus.done
          })
        )
      }
    }
  }

  public *handleGetDatasets(params: getDatasetsParams) {
    const datasetLoadingState: OctainDatasetState = yield select(
      DatasetModule.selectDatasetState
    )

    yield put(
      this.setState({
        ...datasetLoadingState,
        datasets: defaultRepository(),
        status: FetchStatus.loading
      })
    )

    const rawOctainDatasets: ResponseModel = yield this.modules.currentSession.authCheck(
      yield getDatasets(params)
    )

    const datasetState: OctainDatasetState = yield select(
      DatasetModule.selectDatasetState
    )

    if (rawOctainDatasets.status !== 200) {
      yield put(
        this.setState({
          ...datasetState,
          datasets: defaultRepository(),
          status: FetchStatus.error
        })
      )
      return
    }

    const parsedArray = parseDataset(rawOctainDatasets.data)

    const convertedDatasets: OctainDatasetState = {
      ...datasetState,
      datasets: parseArrayToBaseRepo(parsedArray),
      status: FetchStatus.done
    }
    yield put(this.setState(convertedDatasets))
  }

  // createDataset

  public *uploadDataset(file: FormData, id: string) {
    const channel = yield call(createDatasetUploadChannel, file)

    while (true) {
      const { event, error, success } = yield take(channel)

      if (error) {
        return error
      }
      if (success) {
        return success
      }
      const datasetState: OctainDatasetState = yield select(
        DatasetModule.selectDatasetState
      )
      const oldProgress = datasetState.uploadStatus.items[id]
      const newProgress: UploadStatus = {
        ...oldProgress,
        progress: event.loaded
      }

      yield put(
        this.setState({
          ...datasetState,
          uploadStatus: updateBaseRepoElements(
            [newProgress],
            datasetState.uploadStatus
          )
        })
      )
    }
  }

  public *handleCreateDataset(file: Array<File>, type: DatasetTypes): {} {
    let data = new FormData()
    data.append('file', file[0], file[0].name)
    data.append('type', DatasetTypes[type])

    const preLoadingState: OctainDatasetState = yield select(
      DatasetModule.selectDatasetState
    )
    const fileUpload: UploadStatus = {
      id: uuid.v4(),
      name: file[0].name,
      state: FetchStatus.loading,
      progress: 0,
      size: file[0].size
    }

    yield put(
      this.setState({
        ...preLoadingState,
        uploadStatus: parseArrayToExistingBaseRepo(
          [fileUpload],
          preLoadingState.uploadStatus
        )
      })
    )

    const rawOctainDatasets: ResponseModel = yield this.uploadDataset(
      data,
      fileUpload.id
    )

    const datasetState: OctainDatasetState = yield select(
      DatasetModule.selectDatasetState
    )

    if (rawOctainDatasets.status !== 200) {
      yield put(
        this.setState({
          ...datasetState,
          status: FetchStatus.error
        })
      )
      return
    }
    const parsedArray = parseDataset(rawOctainDatasets.data)

    const convertedDatasets: OctainDatasetState = {
      ...datasetState,
      datasets: parseArrayToBaseRepo(parsedArray),
      status: FetchStatus.done,
      uploadStatus: removeFromBaseRepo(fileUpload.id, datasetState.uploadStatus)
    }
    yield put(this.setState(convertedDatasets))
  }

  public static selectDatasetState(state: AppState): OctainDatasetState {
    return state.datasets
  }
}
