import { ResponseModel } from 'common/client'
import { FetchStatus } from 'enums'
import { parseCSV } from 'common/redux/sitka/octain_dataset/util'
import { OctainPrediction } from 'common/redux/sitka/octain_prediction/core'

export const getResponseStatus = (response: ResponseModel): FetchStatus =>
  response.status === 200 ? FetchStatus.done : FetchStatus.error

export const getDataFromResponse = (response: ResponseModel): any =>
  response.status === 200 ? response.data : ','

export const parseCSVinPredictions = (
  octainPredictions: Array<any>
): Array<any> => {
  return octainPredictions.map(prediction => parseCSVinPrediction(prediction))
}

export const parseCSVinPrediction = (
  prediction: OctainPrediction
): OctainPrediction => ({
  ...prediction,
  preview: prediction.preview
    ? parseCSV(prediction.preview)
    : prediction.preview
})
